import React from "react";
import styles from "./Faq.module.css";
import BoardComponents from "../../components/BoardComponents/BoardComponents";
import { useLocation } from "react-router-dom";

function Faq() {
  const location = useLocation();
  const search = location.state?.search || "";
  const pageName = "faq";
  const newsNumber = 100;
  return (
    <div className={styles.technicalAccountSection}>
      <BoardComponents
        showSearch
        showCheckbox
        showNumberColumn
        pages={pageName}
        line={newsNumber}
        initialSearch={search}
        className={styles.technicalAccountContents}
      />
    </div>
  );
}

export default Faq;
