import React, { useState } from "react";
import styles from "./SignUp.module.css";
import Button from "../../components/ui/Button";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/ui/Modal";
import { useForm } from "react-hook-form";
import { addUser } from "../../api/crud";

function SignUp() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isSubmitted, errors },
    trigger,
    setValue,
  } = useForm();

  const handleBlur = (field) => {
    trigger(field);
  };

  const handleSignup = async () => {
    const user = getValues();

    console.log(user);
    try {
      await addUser(user);
      setModalOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAllChecked = (e) => {
    const checked = e.target.checked;
    setValue("termsofuse__slz", checked);
    setValue("termsofuse__privacy", checked);
    setValue("termsofuse__marketing", checked);
    setValue("termsofuse__marketing_sms", checked);
    setValue("termsofuse__marketing_call", checked);
    setValue("termsofuse__marketing_email", checked);
  };

  const handleMarketingChecked = (e) => {
    const checked = e.target.checked;
    setValue("termsofuse__marketing_sms", checked);
    setValue("termsofuse__marketing_call", checked);
    setValue("termsofuse__marketing_email", checked);
  };

  return (
    <section className={styles.signUpSection}>
      <div className={styles.signUp}>
        <div className={styles.signUpTitle}>Sign Up</div>
        <div className={styles.signUpContents}>
          <form
            onSubmit={handleSubmit(handleSignup)}
            className={styles.signUpContents}
          >
            {/* ID 입력 필드 */}
            <div className={styles.contentsSection}>
              <label htmlFor="id">ID *</label>
              <div className={styles.contentsSection__validation}>
                <div className={styles.contentsSection__validation__button}>
                  <input
                    type="text"
                    id="id"
                    name="id"
                    placeholder="Lowercase letters and numbers, between 5 and 20 characters"
                    aria-invalid={
                      isSubmitted ? (errors.id ? "true" : "false") : undefined
                    }
                    {...register("id", {
                      required: "ID is required.",
                      pattern: {
                        value: /^[a-z0-9]{5,20}$/,
                        message:
                          "Please enter a combination of lowercase letters and numbers, between 5 and 20 characters.",
                      },
                    })}
                    onBlur={() => handleBlur("id")}
                  />
                  <Button
                    color="var(--color-darkGray)"
                    text="Verify"
                    fontColor="white"
                    width="150px"
                    height="40px"
                  />
                </div>
                <div className={styles.validation}>
                  <p>
                    {errors.id && (
                      <>
                        <IoAlertCircleOutline />
                        {errors.id.message}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* Password 입력 필드 */}
            <div className={styles.contentsSection}>
              <label htmlFor="password">Password *</label>
              <div className={styles.contentsSection__validation}>
                <input
                  type="password"
                  id="password"
                  placeholder="Combination of numbers, letters, and special characters, at least 8 characters long"
                  aria-invalid={
                    isSubmitted
                      ? errors.password
                        ? "true"
                        : "false"
                      : undefined
                  }
                  {...register("password", {
                    required: "Password is required.",
                    pattern: {
                      value:
                        /^(?=.*[a-zA-Z])(?=.*[?!@#$%^*+=-])(?=.*[0-9]).{8,16}$/,
                      message:
                        "Please enter at least 8 characters, combining numbers, letters, and special characters.",
                    },
                  })}
                  onBlur={() => handleBlur("password")}
                />
                <div className={styles.validation}>
                  <p>
                    {errors.password && (
                      <>
                        <IoAlertCircleOutline />
                        {errors.password.message}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* Confirm Password 입력 필드 */}
            <div className={styles.contentsSection}>
              <label htmlFor="passwordConfirm">Confirm Password *</label>
              <div className={styles.contentsSection__validation}>
                <input
                  type="password"
                  id="passwordConfirm"
                  placeholder="Combination of numbers, letters, and special characters, at least 8 characters long"
                  aria-invalid={
                    isSubmitted
                      ? errors.passwordConfirm
                        ? "true"
                        : "false"
                      : undefined
                  }
                  {...register("passwordConfirm", {
                    required: "Please enter password confirmation.",
                    validate: {
                      check: (val) => {
                        if (getValues("password") !== val) {
                          return "Passwords do not match.";
                        }
                      },
                    },
                  })}
                  onBlur={() => handleBlur("passwordConfirm")}
                />
                <div className={styles.validation}>
                  <p>
                    {errors.passwordConfirm && (
                      <>
                        <IoAlertCircleOutline />
                        {errors.passwordConfirm.message}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* Name 입력 필드 */}
            <div className={styles.contentsSection}>
              <label htmlFor="name">Name *</label>
              <div className={styles.contentsSection__validation}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Between 2 and 20 characters in English or Korean."
                  aria-invalid={
                    isSubmitted ? (errors.name ? "true" : "false") : undefined
                  }
                  {...register("name", {
                    required: "Name is required.",
                    pattern: {
                      value: /^[A-Za-z가-힣]{2,20}$/,
                      message:
                        "Please enter between 2 and 20 characters in English or Korean.",
                    },
                  })}
                  onBlur={() => handleBlur("name")}
                />
                <div className={styles.validation}>
                  <p>
                    {errors.name && (
                      <>
                        <IoAlertCircleOutline />
                        {errors.name.message}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* Tel 입력 필드 */}
            <div className={styles.contentsSection}>
              <label htmlFor="tel">Tel *</label>
              <div className={styles.contentsSection__validation}>
                <div className={styles.contentsSection__validation__button}>
                  <input
                    type="tel"
                    id="tel"
                    name="tel"
                    placeholder="Please enter your contact information"
                    aria-invalid={
                      isSubmitted ? (errors.tel ? "true" : "false") : undefined
                    }
                    {...register("tel", {
                      required: "Contact information is required.",
                      pattern: {
                        value: /^\d{3}-\d{3,4}-\d{4}$/,
                        message:
                          "Please enter your contact information, including ‘-’",
                      },
                    })}
                    onBlur={() => handleBlur("tel")}
                  />
                  <Button
                    color="var(--color-darkGray)"
                    text="Verify"
                    fontColor="white"
                    width="150px"
                    height="40px"
                  />
                </div>
                <div className={styles.validation}>
                  <p>
                    {errors.tel && (
                      <>
                        <IoAlertCircleOutline />
                        {errors.tel.message}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* Email 입력 필드 */}
            <div className={styles.contentsSection}>
              <label htmlFor="email">Email *</label>
              <div className={styles.contentsSection__validation}>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Please enter your email address"
                  aria-invalid={
                    isSubmitted ? (errors.email ? "true" : "false") : undefined
                  }
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "This is not a valid email format.",
                    },
                  })}
                  onBlur={() => handleBlur("email")}
                />
                <div className={styles.validation}>
                  <p>
                    {errors.email && (
                      <>
                        <IoAlertCircleOutline />
                        {errors.email.message}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* Company 입력 필드 */}
            <div className={styles.contentsSection}>
              <label htmlFor="company">Company Name</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Please enter your company name"
                minLength="1"
                maxLength="100"
                {...register("company")}
                onBlur={() => handleBlur("company")}
              />
            </div>

            {/* Terms of Use 섹션 */}
            <div className={styles.termsofuse}>
              <div className={styles.checkboxSection__checkbox}>
                <input
                  type="checkbox"
                  id="allTerms"
                  name="allTerms"
                  {...register("allTerms")}
                  onChange={handleAllChecked}
                ></input>
                <label htmlFor="allTerms">
                  Agree to all terms and conditions
                </label>
              </div>
              <hr className={styles.hr} />
              <ul>
                <li>
                  <div className={styles.checkboxArrowSection}>
                    <div className={styles.checkboxArrowSection__checkbox}>
                      <input
                        type="checkbox"
                        id="termsofuse__slz"
                        name="termsofuse__slz"
                        aria-invalid={
                          isSubmitted
                            ? errors.termsofuse__slz
                              ? "true"
                              : "false"
                            : undefined
                        }
                        {...register("termsofuse__slz", {
                          required: "You must agree SLZ Terms of Use",
                        })}
                      />
                      <label htmlFor="termsofuse__slz">
                        [Required] SLZ Terms of Use
                      </label>
                    </div>

                    <IoIosArrowForward />
                  </div>
                  <div className={styles.validation}>
                    <p>
                      {errors.termsofuse__slz && (
                        <>
                          <IoAlertCircleOutline />
                          {errors.termsofuse__slz.message}
                        </>
                      )}
                    </p>
                  </div>
                </li>
                <li>
                  <div className={styles.checkboxArrowSection}>
                    <div className={styles.checkboxArrowSection__checkbox}>
                      <input
                        type="checkbox"
                        id="termsofuse__privacy"
                        name="termsofuse__privacy"
                        aria-invalid={
                          isSubmitted
                            ? errors.termsofuse__privacy
                              ? "true"
                              : "false"
                            : undefined
                        }
                        {...register("termsofuse__privacy", {
                          required:
                            "You must consent to the collection and use of personal information.",
                        })}
                      />
                      <label htmlFor="termsofuse__privacy">
                        [Required] Consent to the collection and use of personal
                        information
                      </label>
                    </div>

                    <IoIosArrowDown />
                  </div>
                  <div className={styles.validation}>
                    <p>
                      {errors.termsofuse__privacy && (
                        <>
                          <IoAlertCircleOutline />
                          {errors.termsofuse__privacy.message}
                        </>
                      )}
                    </p>
                  </div>
                  <textarea id="termsofuse_slz_text" name="termsofuse_slz">
                    Article 1 (Purpose) These terms and conditions are intended
                    to stipulate the rights, obligations, and responsibilities
                    of the cyber mall and users in using the internet-related
                    services (hereinafter referred to as "services") provided by
                    the cyber mall operated by the business operator
                    (hereinafter referred to as "mall"). ※These terms and
                    conditions apply mutatis mutandis to electronic commerce
                    using PC communication, wireless, etc., as long as it does
                    not violate their nature.
                  </textarea>
                </li>
                <li>
                  <div className={styles.checkboxArrowSection}>
                    <div className={styles.checkboxArrowSection__checkbox}>
                      <input
                        type="checkbox"
                        id="termsofuse__marketing"
                        name="termsofuse__marketing"
                        {...register("termsofuse__marketing")}
                        onChange={handleMarketingChecked}
                      />
                      <label htmlFor="termsofuse__marketing">
                        [Optional] Consent to the collection and use of
                        marketing personal information
                      </label>
                    </div>
                    <IoIosArrowForward />
                  </div>
                  <ul>
                    <li>
                      <div className={styles.checkboxSection__checkbox}>
                        <input
                          type="checkbox"
                          id="termsofuse__marketing_sms"
                          name="termsofuse__marketing_sms"
                          {...register("termsofuse__marketing_sms")}
                        />
                        <label htmlFor="termsofuse__marketing_sms">
                          [Optional] Consent to receive SMS
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className={styles.checkboxSection__checkbox}>
                        <input
                          type="checkbox"
                          id="termsofuse__marketing_call"
                          name="termsofuse__marketing_call"
                          {...register("termsofuse__marketing_call")}
                        />
                        <label htmlFor="termsofuse__marketing_call">
                          [Optional] Consent to receive phone calls
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className={styles.checkboxSection__checkbox}>
                        <input
                          type="checkbox"
                          id="termsofuse__marketing_email"
                          name="termsofuse__marketing_email"
                          {...register("termsofuse__marketing_email")}
                        />
                        <label htmlFor="termsofuse__marketing_email">
                          [Optional] Consent to receive emails
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className={styles.buttonSection}>
              <Button
                text="Sign Up"
                primary
                width="200px"
                height="50px"
                type="submit"
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>

        {modalOpen && <Modal text="Sign up completed" pageName="Login" />}
      </div>
    </section>
  );
}

export default SignUp;
