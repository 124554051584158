import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import styles from "./FaqDetail.module.css";
import Button from "../../components/ui/Button";
import { deleteFaq, fetchFaq } from "../../api/crud"; // fetchFaq와 deleteFaq 사용

function FaqDetail() {
  const { id } = useParams(); // URL에서 ID 가져오기
  const postId = parseInt(id, 10); // id 값을 숫자로 변환
  const navigate = useNavigate();

  // 게시글 정보를 상태로 관리
  const [totalPosts, setTotalPosts] = useState(0);
  const [post, setPost] = useState(null);

  useEffect(() => {
    const loadPost = async () => {
      try {
        const allFaqs = await fetchFaq(); // 모든 FAQ 데이터 가져오기
        setTotalPosts(allFaqs.length);
        const fetchedPost = allFaqs.find((faq) => faq.id === id); // ID에 맞는 게시글 찾기
        setPost(fetchedPost);
      } catch (error) {
        console.log(error);
      }
    };

    loadPost(); // 컴포넌트가 마운트되면 게시글 정보 로드
  }, [id]);

  const handleDeleteFaq = async (id) => {
    try {
      await deleteFaq(id);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.technicalAccount}>
      <div className={styles.technicalAccountContents}>
        <div className={styles.titleSection}>
          <h1 className={styles.title}>{post.title}</h1>
          <span>| {post.type}</span>
        </div>
        <div className={styles.date}>{post.date}</div>
        <hr className={styles.hr} />
        <div className={styles.image}>
          <img
            src={post.images ? post.images : "/images/logo.png"}
            alt="faq_image"
          />
        </div>
        <div className={styles.contents}>
          <p>{post.contents}</p>
        </div>
        {/* <div className={styles.editButton}>
          <Link
            to={`/faq/${id}/edit`}
            state={{ id, type, title, date, contents }}
          >
            <Button text="Edit" color="var(--color-lightGray)" />
          </Link>
          <Button
            text="Delete"
            color="var(--color-lightGray)"
            onClick={() => handleDeleteFaq(id)}
          />
        </div> */}
        <hr className={styles.hr} />
        <div className={styles.postButton}>
          {postId > 1 ? (
            <Link to={`/faq/${postId - 1}`}>
              <Button text="<Prev" />
            </Link>
          ) : (
            <Button text="<Prev" disabled />
          )}
          {postId < totalPosts ? (
            <Link to={`/faq/${postId + 1}`}>
              <Button text="Next>" />
            </Link>
          ) : (
            <Button text="Next>" />
          )}
          <Link to="/faq">
            <Button text="List" primary />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FaqDetail;
