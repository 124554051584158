import React from "react";
import styles from "./OrderDetail.module.css";
import Button from "../../components/ui/Button";

function OrderDetail() {
  return (
    <>
      <div className={styles.orderDetailSection}>
        <div className={styles.orderDetailTitle}>
          <h1 className={styles.heading1}>주문 상세보기</h1>
          <p>주문번호 20240428-0006235 </p>
        </div>
        <hr className={styles.hr} />
        <div className={styles.orderDetailSection__2}>
          <div className={styles.orderDetailSection__orderItem}>
            <h1 className={styles.heading3}>주문상품</h1>
            <div className={styles.orderDetailSection__orderItem__contents}>
              <table className={styles.table}>
                <tr>
                  <td>솔루션명</td>
                  <td>콘버스 (Converse)</td>
                </tr>
                <tr>
                  <td>등급</td>
                  <td>Basic (30일 무료체험)</td>
                </tr>
                <tr>
                  <td>가격</td>
                  <td>월 1,990,000원 (24.5.28. 과금예정)</td>
                </tr>
              </table>
              <div
                className={styles.orderDetailSection__orderItem__solutionImage}
              >
                <img
                  src="/images/Converse/Converse_icon.png"
                  alt="converse icon"
                  width={90}
                />
              </div>
            </div>
          </div>
          <div className={styles.orderDetailSection__orderDetailAmount}>
            <div>
              <h1 className={styles.heading3}>결제금액</h1>
              <div className={styles.price}>
                <p>주문상품 금액</p>
                <p>1,980,000원</p>
              </div>
              <div className={styles.price}>
                <p>추가옵션 및 할인금액</p>
                <p>-</p>
              </div>
              <hr className={styles.hr} />
              <div className={styles.price}>
                <p>총 결제금액</p>
                <p className={styles.color}>1,980,000원</p>
              </div>
              <div className={styles.price}>
                <p>결제수단</p>
                <p>신용카드</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.orderDetailSection__contractorInfo}>
          <h1 className={styles.heading3}>계약자 정보</h1>
          <div>
            <div className={styles.tableSection}>
              <table className={styles.table}>
                <tr>
                  <th>회사명</th>
                  <td>주식회사 에스엘즈</td>
                  <th>담당자</th>
                  <td>홍길동</td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td>010-1234-5678</td>
                  <th>이메일</th>
                  <td>gildong_slz@slz.kr</td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td colSpan="3">
                    경기도 시흥시 서울대학로 173, 서울대학교 시흥캠퍼스
                    교육협력동 608호
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className={styles.buttonSection}>
          <Button
            color="var(--color-darkGray)"
            fontColor="white"
            height={50}
            text="신용카드 매출전표"
          />
          <Button height={50} text="세금계산서" />
          <Button height={50} text="현금영수증" />
          <Button height={50} text="사업자등록증" />
        </div>
      </div>
    </>
  );
}

export default OrderDetail;
