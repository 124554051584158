import React, { useState } from "react";
import styles from "./Notice.module.css";

function NoticeCheckbox({ onSubmit }) {
  const [checkedValue, setCheckedValue] = useState(null); // 하나의 값만 선택하도록 설정

  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedValue(value);
      onSubmit([value]); // 선택된 하나의 값을 배열로 전달
    } else {
      setCheckedValue(null);
      onSubmit([]); // 선택을 해제한 경우 빈 배열로 전달
    }
  };

  return (
    <>
      <div className={styles.updateNewsTitle}>
        <h1 className={styles.heading1}>Latest Updates</h1>
      </div>
      <form className={styles.checkBox}>
        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="Notice"
            name="type"
            value="Notice"
            checked={checkedValue === "Notice"} // 하나만 선택되도록 설정
            onChange={handleChange}
          />
          <label htmlFor="Notice">Notice</label>
        </div>

        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="ROUTi-AI"
            name="type"
            value="ROUTi-AI"
            checked={checkedValue === "ROUTi-AI"}
            onChange={handleChange}
          />
          <label htmlFor="ROUTi-AI">ROUTi-AI</label>
        </div>
        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="ROUTi-AR"
            name="type"
            value="ROUTi-AR"
            checked={checkedValue === "ROUTi-AR"}
            onChange={handleChange}
          />
          <label htmlFor="ROUTi-AR">ROUTi-AR</label>
        </div>
      </form>
    </>
  );
}

export default NoticeCheckbox;
