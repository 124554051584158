import React, { useEffect, useState } from "react";
import styles from "./MyPage.module.css";
import Button from "../../components/ui/Button";
import { IoIosCheckmark } from "react-icons/io";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { fetchUser, updateUser } from "../../api/crud";

function MyPage() {
  return (
    <div className={styles.mypageSection}>
      <div className={styles.menuSection}>
        <div className={styles.menuSection__title}>
          <h1 className={styles.heading1}>My Page</h1>
          <ul className={styles.menuSection__title__list}>
            <li>
              <NavLink
                to="/mypage/memberinfo"
                style={({ isActive }) => {
                  return {
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "#1e7d4f" : "#191919",
                  };
                }}
              >
                Manage Member Information
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/mypage/ticket"
                style={({ isActive }) => {
                  return {
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "#1e7d4f" : "#191919",
                  };
                }}
              >
                Manage Tickets
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/mypage/securitysetting"
                style={({ isActive }) => {
                  return {
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "#1e7d4f" : "#191919",
                  };
                }}
              >
                Security Settings
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.stateBox}>
          <div className={styles.inUseService}>
            <h3>Services in Use</h3>
            <p>Converse | Basic</p>
          </div>
          <div className={styles.recentLogin}>
            <h3>Recent Login</h3>
            <p>March 21, 2024</p>
          </div>
        </div>
      </div>
      <Outlet className={styles.outlet} />
    </div>
  );
}

export function MemberInfo() {
  const navigate = useNavigate();
  const [memberInfoModify, setMemberInfoModify] = useState(false);
  const [user, setUser] = useState([]);
  const { name, id, tel, email, address, company } = user;
  const [error, setError] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const data = await fetchUser();
        setUser(data);
      } catch (error) {
        setError(error);
      }
    };
    getUser();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };
  const handleUpdateUser = async () => {
    try {
      setMemberInfoModify(false);
      navigate(-1);
      await updateUser(id, user);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.pageSection}>
      <div className={styles.pageSection__title}>
        <h2 className={styles.heading2}>Manage Member Information</h2>
        {!memberInfoModify && (
          <Button onClick={() => setMemberInfoModify(true)} text="Edit" />
        )}
      </div>
      <hr className={styles.hr} />
      {!memberInfoModify ? (
        <div className={styles.tableSection}>
          <table className={styles.table1}>
            <tr>
              <th>Name</th>
              <td>{name}</td>
              <th>ID</th>
              <td>{id}</td>
            </tr>
            <tr>
              <th>Contact</th>
              <td>{tel}</td>
              <th>Email</th>
              <td colSpan="3">{email}</td>
            </tr>

            <tr>
              <th>Address</th>
              <td colSpan="3">{address}</td>
            </tr>
            <tr>
              <th>Company Name</th>
              <td colSpan="3">{company}</td>
            </tr>
          </table>
        </div>
      ) : (
        <div className={styles.tableSection}>
          <table className={styles.table3}>
            <tr>
              <th>
                <label htmlFor="name">Name</label>
              </th>
              <td>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Please enter your name"
                  required
                  minLength="2"
                  maxLength="100"
                  value={name}
                  onChange={handleChange}
                />
              </td>
              <th>
                <label htmlFor="id">ID</label>
              </th>
              <td>
                <input
                  type="text"
                  id="id"
                  name="id"
                  placeholder="Please enter your ID"
                  required
                  minLength="5"
                  maxLength="100"
                  value={id}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label htmlFor="tel">Mobile</label>
              </th>
              <td>
                <input
                  type="tel"
                  id="tel"
                  name="tel"
                  placeholder="Please enter your mobile number"
                  required
                  minLength="5"
                  maxLength="100"
                  value={tel}
                  onChange={handleChange}
                />
              </td>
              <th>
                <label htmlFor="email">Email</label>
              </th>
              <td colSpan="3">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Please enter your email address"
                  required
                  minLength="5"
                  maxLength="100"
                  value={email}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th>
                <label htmlFor="address">Address</label>
              </th>
              <td colSpan="3">
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Address"
                  required
                  minLength="6"
                  maxLength="100"
                  value={address}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label htmlFor="company">Company Name</label>
              </th>
              <td colSpan="3">
                <input
                  type="text"
                  id="company"
                  name="company"
                  value="SLZ Co., Ltd."
                  placeholder="Please enter your company name"
                  minLength="12"
                  maxLength="100"
                  value={company}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </table>
          <div className={styles.buttonSection}>
            <Button text="Save Changes" primary onClick={handleUpdateUser} />
            <Button text="Cancel" onClick={() => setMemberInfoModify(false)} />
          </div>
        </div>
      )}
      {!memberInfoModify && (
        <div
          className={styles.withDrawal}
          onClick={() => navigate("/deleteaccount")}
        >
          Withdraw Membership >
        </div>
      )}
    </div>
  );
}

export function Ticket() {
  return (
    <div className={styles.pageSection}>
      <div className={styles.pageSection__title}>
        <h2 className={styles.heading2}>Service Usage</h2>
      </div>
      <hr className={styles.hr} />
      <table className={styles.table2}>
        <tr>
          <th width={82}>Status</th>
          <th>Product Name</th>
          <th width={136}>Payment Amount</th>
          <th>Payment Method</th>
          <th>Payment Date</th>
          <th width={181}>Usage Period</th>
        </tr>
        <tr>
          <td>
            <b>Subscribed</b>
          </td>
          <td>Converse | Basic</td>
          <td>1,980,000 KRW</td>
          <td>Credit Card</td>
          <td>April 27, 2024, 14:00</td>
          <td>March 27, 2024, 14:00 ~ April 26, 2024, 13:59</td>
        </tr>
        <tr>
          <td>Expired</td>
          <td>Converse | Basic</td>
          <td>1,980,000 KRW</td>
          <td>Credit Card</td>
          <td>April 27, 2024, 14:00</td>
          <td>March 27, 2024, 14:00 ~ April 26, 2024, 13:59</td>
        </tr>
        <tr>
          <td>Expired</td>
          <td>Converse | Basic</td>
          <td>1,980,000 KRW</td>
          <td>Credit Card</td>
          <td>April 27, 2024, 14:00</td>
          <td>March 27, 2024, 14:00 ~ April 26, 2024, 13:59</td>
        </tr>
        <tr>
          <td>Expired</td>
          <td>Converse | Basic</td>
          <td>1,980,000 KRW</td>
          <td>Credit Card</td>
          <td>April 27, 2024, 14:00</td>
          <td>March 27, 2024, 14:00 ~ April 26, 2024, 13:59</td>
        </tr>
      </table>
    </div>
  );
}

export function SecuritySetting() {
  const [securityPasswordModify, setSecurityPasswordModify] = useState(false);
  return (
    <div className={styles.pageSection}>
      <div className={styles.pageSection__title}>
        <h2 className={styles.heading2}>Security Settings</h2>
        {!securityPasswordModify && (
          <div onClick={() => setSecurityPasswordModify(true)}>
            Change Password >
          </div>
        )}
      </div>
      <hr className={styles.hr} />
      {!securityPasswordModify ? (
        <table className={styles.table2}>
          <tr>
            <th>Basic Security Settings</th>
            <td>Set up 2-factor authentication ></td>
          </tr>
          <tr>
            <th>Login Block Settings</th>
            <td className={styles.table2__align}>
              <input type="checkbox" id="otherArea" name="otherArea" />
              <label htmlFor="otherArea">Block login from other regions</label>
              <input type="checkbox" id="overseas" name="overseas" />
              <label htmlFor="overseas">Block overseas login</label>
            </td>
          </tr>
        </table>
      ) : (
        <div className={styles.passwordContentsAuth}>
          <div className={styles.passwordContents__currentPassord}>
            <p className={styles.passwordContents__currentPassord__id}>ID</p>
            <p>&nbsp; gildong_slz</p>
          </div>
          <div className={styles.passwordContents__currentPassord}>
            <label htmlFor="currentPassword">Current Password</label>
            <div className={styles.passwordContentsValidation}>
              <input
                className={styles.passwordContentsInput}
                type="password"
                id="currentPassword"
                placeholder="Current Password"
              />
            </div>
          </div>
          <div className={styles.passwordContents__currentPassord}>
            <label htmlFor="newPassword">New Password</label>
            <div className={styles.passwordContentsValidation}>
              <input
                className={styles.passwordContentsInput}
                type="password"
                id="newPassword"
                placeholder="New Password"
              />
            </div>
          </div>
          <div className={styles.passwordContents__currentPassord}>
            <label htmlFor="newPasswordCheck">Confirm New Password</label>
            <div className={styles.passwordContentsValidation}>
              <input
                className={styles.passwordContentsInput}
                type="password"
                id="newPasswordCheck"
                placeholder="Confirm New Password"
              />
              <div className={styles.validation}>
                <p>
                  <IoIosCheckmark />
                  Combine at least 6 characters of English or English and
                  numbers
                </p>
              </div>
            </div>
          </div>
          <hr className={styles.hr} />
          <div className={styles.validationInfo}>
            <p>
              You can use a combination of 8-16 characters, including
              uppercase/lowercase English letters, numbers, and special symbols.
            </p>
            <p>
              Please avoid using easy-to-guess passwords such as personal
              information-related numbers, consecutive numbers, or consecutive
              keyboard arrangements, as they can be easily figured out by
              others.
            </p>
            <p>
              Avoid using passwords that have been used before or are used on
              other sites, and change your password periodically.
            </p>
          </div>
          <div className={styles.buttonSection}>
            <Button
              text="Change Password"
              primary
              onClick={() => setSecurityPasswordModify(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MyPage;
