import React from "react";
import styles from "./Notice.module.css";
import BoardComponents from "../../components/BoardComponents/BoardComponents";
import { useLocation } from "react-router-dom";

function Notice() {
  const location = useLocation();
  const search = location.state?.search || "";
  const pageName = "notice";
  const newsNumber = 100;

  return (
    <div className={styles.updateNewsSection}>
      <BoardComponents
        showSearch
        showCheckbox
        showNumberColumn
        pages={pageName}
        line={newsNumber}
        initialSearch={search}
        className={styles.updateNewsContents}
      />
    </div>
  );
}

export default Notice;
