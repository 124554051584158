import React, { useState } from "react";
import styles from "./Faq.module.css";

function FaqCheckbox({ onSubmit }) {
  const [checkedValue, setCheckedValue] = useState(null); // 하나의 값만 선택하도록 설정

  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedValue(value);
      onSubmit([value]); // 선택된 하나의 값을 배열로 전달
    } else {
      setCheckedValue(null);
      onSubmit([]); // 선택을 해제한 경우 빈 배열로 전달
    }
  };

  return (
    <>
      <div className={styles.technicalAccountTitle}>
        <h1 className={styles.heading1}>FAQ</h1>
      </div>
      <form className={styles.checkBox}>
        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="Auth"
            name="type"
            value="Auth"
            checked={checkedValue === "Auth"} // 하나만 선택되도록 설정
            onChange={handleChange}
          />
          <label htmlFor="Auth">Auth</label>
        </div>
        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="Download"
            name="type"
            value="Download"
            checked={checkedValue === "Download"}
            onChange={handleChange}
          />
          <label htmlFor="Download">Download</label>
        </div>
        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="ROUTi-AI"
            name="type"
            value="ROUTi-AI"
            checked={checkedValue === "ROUTi-AI"}
            onChange={handleChange}
          />
          <label htmlFor="ROUTi-AI">ROUTi-AI</label>
        </div>
        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="ROUTi-AR"
            name="type"
            value="ROUTi-AR"
            checked={checkedValue === "ROUTi-AR"}
            onChange={handleChange}
          />
          <label htmlFor="ROUTi-AR">ROUTi-AR</label>
        </div>
        <div className={styles.checkBoxContents}>
          <input
            type="checkbox"
            id="Etc"
            name="type"
            value="Etc"
            checked={checkedValue === "Etc"}
            onChange={handleChange}
          />
          <label htmlFor="Etc">Etc</label>
        </div>
      </form>
    </>
  );
}

export default FaqCheckbox;
