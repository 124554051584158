import React, { useEffect, useState } from "react";
import styles from "./Payment.module.css";
import Button from "../../components/ui/Button";
import { IoIosCheckmark, IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";

function Payment() {
  const navigate = useNavigate();
  const { register, setValue, control } = useForm();
  const allChecked = useWatch({
    control,
    name: "allTerms",
    defaultValue: false,
  });

  const termsChecked = useWatch({
    control,
    name: ["personalInfo", "agreementPayment"],
    defaultValue: [false, false],
  });

  const handleAllChecked = (e) => {
    const checked = e.target.checked;
    setValue("personalInfo", checked);
    setValue("agreementPayment", checked);
  };

  useEffect(() => {
    const allTrue = termsChecked.every((value) => value);
    setValue("allTerms", allTrue);
  }, [termsChecked, setValue]);

  const handleTermChecked = (name, checked) => {
    setValue(name, checked);
  };

  const [memberInfoModify, setMemberInfoModify] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [creditcardOn, setCreditcardOn] = useState(true);
  const [easyPaymentOn, setEasyPaymentOn] = useState(false);
  const [virtualAccountOn, setVirtualAccountOn] = useState(false);
  const [withoutPassbookOn, setWithoutPassbookOn] = useState(false);
  return (
    <div className={styles.paymentSection}>
      <div className={styles.paymentTitle}>
        <h1 className={styles.heading1}>결제하기</h1>
        <hr className={styles.hr} />
      </div>
      <div className={styles.paymentSection__orderItem}>
        <div className={styles.paymentSection__orderItem__title}>
          <h1 className={styles.heading2}>주문상품</h1>
          <div>견적서 출력 ></div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.paymentSection__orderItem__contents}>
          <div className={styles.paymentSection__orderItem__solutionImage}>
            <img
              src="/images/Converse/Converse_icon.png"
              alt="converse icon"
              width={90}
            />
          </div>
          <table className={styles.table}>
            <tr>
              <td>솔루션명</td>
              <td>콘버스 (Converse)</td>
            </tr>
            <tr>
              <td>등급</td>
              <td>Basic (30일 무료체험)</td>
            </tr>
            <tr>
              <td>가격</td>
              <td>월 1,990,000원 (24.5.28. 과금예정)</td>
            </tr>
          </table>
        </div>
      </div>

      <div className={styles.paymentSection__contractorInfo}>
        <div className={styles.paymentSection__contractorInfo__title}>
          <h1 className={styles.heading2}>계약자 정보</h1>
          {!memberInfoModify && (
            <div
              className={styles.touchBtn}
              onClick={() => setMemberInfoModify(true)}
            >
              수정하기 >
            </div>
          )}
        </div>
        <hr className={styles.hr} />
        <div>
          {!memberInfoModify ? (
            <div className={styles.tableSection}>
              <table className={styles.table}>
                <tr>
                  <th>회사명</th>
                  <td>주식회사 에스엘즈</td>
                  <th>담당자</th>
                  <td>홍길동</td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td>010-1234-5678</td>
                  <th>이메일</th>
                  <td>gildong_slz@slz.kr</td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td colSpan="3">
                    경기도 시흥시 서울대학로 173, 서울대학교 시흥캠퍼스
                    교육협력동 608호
                  </td>
                </tr>
              </table>
            </div>
          ) : (
            <div className={styles.tableSection}>
              <table className={styles.table}>
                <tr>
                  <th>
                    <label htmlFor="company">회사명</label>
                  </th>
                  <td>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value="주식회사 에스엘즈"
                      placeholder="회사명을 입력해주세요"
                      minlength="12"
                      maxlength="100"
                    />
                  </td>
                  <th>
                    <label htmlFor="name">담당자</label>
                  </th>
                  <td>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="이름을 기입해주세요"
                      required
                      minlength="6"
                      maxlength="100"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="tel">연락처</label>
                  </th>
                  <td>
                    <input
                      type="tel"
                      id="tel"
                      name="tel"
                      placeholder="휴대폰번호를를 입력해주세요"
                      required
                      minlength="6"
                      maxlength="100"
                    />
                  </td>
                  <th>
                    <label htmlFor="email">이메일</label>
                  </th>
                  <td>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="이메일 주소를 입력해주세요"
                      required
                      minlength="6"
                      maxlength="100"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="address">주소</label>
                  </th>
                  <td colSpan="3">
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder="주소"
                      required
                      minlength="6"
                      maxlength="100"
                    />
                  </td>
                </tr>
              </table>
              <div className={styles.buttonSection}>
                <Button
                  text="수정하기"
                  primary
                  onClick={() => setMemberInfoModify(false)}
                />
                <Button
                  text="취소하기"
                  onClick={() => setMemberInfoModify(false)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.paymentSection__payment}>
        <div className={styles.paymentSection__paymentMethods}>
          <div className={styles.paymentSection__paymentMethods__title}>
            <h1 className={styles.heading2}>결제방법</h1>
            <hr className={styles.hr} />
          </div>
          <div className={styles.paymentSection__paymentMethods__contents}>
            <div className={styles.buttonSection}>
              <Button
                color={creditcardOn ? "var(--color-darkGray)" : "white"}
                fontColor={creditcardOn ? "white" : "var(--color-text)"}
                width={135}
                height={50}
                text="신용카드"
                onClick={() => {
                  setCreditcardOn(true);
                  setEasyPaymentOn(false);
                  setVirtualAccountOn(false);
                  setWithoutPassbookOn(false);
                }}
              />
              <Button
                color={easyPaymentOn ? "var(--color-darkGray)" : "white"}
                fontColor={easyPaymentOn ? "white" : "var(--color-text)"}
                width={135}
                height={50}
                text="간편결제"
                onClick={() => {
                  setCreditcardOn(false);
                  setEasyPaymentOn(true);
                  setVirtualAccountOn(false);
                  setWithoutPassbookOn(false);
                }}
              />
              <Button
                color={virtualAccountOn ? "var(--color-darkGray)" : "white"}
                fontColor={virtualAccountOn ? "white" : "var(--color-text)"}
                width={135}
                height={50}
                text="가상계좌"
                onClick={() => {
                  setCreditcardOn(false);
                  setEasyPaymentOn(false);
                  setVirtualAccountOn(true);
                  setWithoutPassbookOn(false);
                }}
              />
              <Button
                color={withoutPassbookOn ? "var(--color-darkGray)" : "white"}
                fontColor={withoutPassbookOn ? "white" : "var(--color-text)"}
                width={135}
                height={50}
                text="무통장입금"
                onClick={() => {
                  setCreditcardOn(false);
                  setEasyPaymentOn(false);
                  setVirtualAccountOn(false);
                  setWithoutPassbookOn(true);
                }}
              />
            </div>
            {creditcardOn && (
              <div>
                <div className={styles.noticeSection__title}>
                  <IoIosCheckmark />
                  <p>신용카드 이용안내</p>
                </div>
                <div className={styles.creditNoteSection}>
                  <img
                    src="/images/payment.png"
                    alt="credit benefits"
                    width={500}
                  />
                </div>
              </div>
            )}
            {easyPaymentOn && (
              <div>
                <div className={styles.payment}>
                  <div className={styles.payment_align}>
                    <input type="radio" name="payment" id="paypal" />
                    <label htmlFor="paypal">
                      <img src="/images/icon/paypal_icon.png" alt="paypal" />
                    </label>
                  </div>
                  <div className={styles.payment_align}>
                    <input type="radio" name="payment" id="naverpay" />
                    <label htmlFor="paypal">
                      <img src="/images/icon/npay_icon.png" alt="naverpay" />
                    </label>
                  </div>
                  <div className={styles.payment_align}>
                    <input type="radio" name="payment" id="payco" />
                    <label htmlFor="payco">
                      <img src="/images/icon/payco_icon.png" alt="payco" />
                    </label>
                  </div>
                </div>
                <div className={styles.noticeSection__title}>
                  <IoIosCheckmark />
                  <p>간편결제 이용안내</p>
                </div>
                <img src="/images/easypayment.png" alt="credit benefits" />
              </div>
            )}
            {withoutPassbookOn && (
              <div className={styles.withoutPassbookOnSection}>
                <div className={styles.withoutPassbookOnSection__input}>
                  <label htmlFor="bankname">입금은행</label>
                  <select id="bankname">
                    <option value="ibk">
                      기업은행 0000-0000-000000 주식회사 에스엘즈
                    </option>
                    <option value="shinhan">
                      신한은행 0000-0000-000000 주식회사 에스엘즈
                    </option>
                  </select>
                </div>
                <div className={styles.withoutPassbookOnSection__input}>
                  <label htmlFor="id">입금자명</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="입금자명"
                    required
                    minlength="6"
                    maxlength="10"
                  />
                </div>
                <div className={styles.withoutPassbookOnSection__certificate}>
                  <div
                    className={
                      styles.withoutPassbookOnSection__certificate__input
                    }
                  >
                    <input
                      type="radio"
                      id="cash"
                      value="cash"
                      name="certificate"
                    />
                    <label htmlFor="cash">현금영수증 발급</label>
                  </div>
                  <div
                    className={
                      styles.withoutPassbookOnSection__certificate__input
                    }
                  >
                    <input
                      type="radio"
                      id="bill"
                      value="bill"
                      name="certificate"
                    />
                    <label htmlFor="id">계산서 발급</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.paymentSection__paymentAmount}>
          <div>
            <h1 className={styles.heading3}>결제금액</h1>
            <div className={styles.price}>
              <p>주문상품 금액</p>
              <p>1,980,000원</p>
            </div>
            <div className={styles.price}>
              <p>추가옵션 및 할인금액</p>
              <p>-</p>
            </div>
            <hr className={styles.hr} />
            <div className={styles.price}>
              <p>총 결제금액</p>
              <p className={styles.color}>1,980,000원</p>
            </div>
          </div>
          <div className={styles.checkBoxContents}>
            <div className={styles.checkboxAlign}>
              <input
                type="checkbox"
                id="agreementAll"
                name="agreementAll"
                {...register("agreementAll")}
                checked={allChecked}
                onChange={handleAllChecked}
              />
              <label htmlFor="agreementAll">전체동의</label>
            </div>
            <hr className={styles.hr} />
            <div className={styles.checkBoxContentsTab}>
              <div className={styles.checkboxAlign}>
                <input
                  type="checkbox"
                  id="personalInfo"
                  name="personalInfo"
                  {...register("personalInfo")}
                  checked={termsChecked[0]}
                  onChange={(e) =>
                    handleTermChecked("personalInfo", e.target.checked)
                  }
                />
                <label htmlFor="personalInfo">
                  개인정보 수집 및 이용동의&nbsp;&nbsp;
                  <span
                    className={`${styles.color} ${styles.underline}`}
                    onClick={() => setTermsModalOpen(true)}
                  >
                    약관보기
                  </span>
                </label>
              </div>
              <div className={styles.checkboxAlign}>
                <input
                  type="checkbox"
                  id="agreementPayment"
                  name="agreementPayment"
                  {...register("agreementPayment")}
                  checked={termsChecked[1]}
                  onChange={(e) =>
                    handleTermChecked("agreementPayment", e.target.checked)
                  }
                />
                <label htmlFor="agreementPayment">
                  구매조건 확인 및 결제진행에 동의
                </label>
              </div>
            </div>
            <div className={styles.buttonSection}>
              <Button
                width="100%"
                height="50px"
                text="결제하기"
                primary
                onClick={() => navigate("/completepayment")}
              />
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className={styles.noticeSection__title}>
        <IoIosCheckmark />
        <p>
          세금계산서, 현금영수증 및 사업자등록증 사본은 결제완료 후 ‘주문
          상세보기’에서 다운로드 가능합니다.
        </p>
      </div>
      {termsModalOpen && (
        <div className={styles.modalBackground}>
          <div className={styles.modalSection}>
            <IoIosCloseCircleOutline onClick={() => setTermsModalOpen(false)} />
            <h3 className={styles.title}>개인정보 수집 및 이용동의</h3>
            <ul>
              <li>
                네이버 회원 계정으로 네이버페이를 이용하여 네이버페이 가맹점에서
                제공하는 상품 및 서비스를 구매하고자 할 경우, 네이버파이낸셜㈜는
                거래 당사자간 원활한 의사소통 및 배송, 상담 등 거래이행을 위하여
                필요한 최소한의 개인정보를 아래와 같이 제공하고 있습니다.
              </li>
              <li>1. 개인정보를 제공받는 자상품 및 서비스 판매자</li>
              <li>
                2. 제공하는 개인정보 항목이름, 네이버 아이디, (휴대)전화번호,
                상품 구매정보,결제수단, 상품 수령인 정보(배송상품:수령인명,
                주소, (휴대)전화번호/ E쿠폰:이름, 네이버 아이디, 휴대전화번호)
              </li>
              <li>
                3. 개인정보를 제공받는 자의 이용목적판매자와 구매자의 원활한
                거래 진행, 본인의사의 확인, 고객상담 및 불만처리/부정이용 방지
                등의 고객관리, 물품배송, 새로운 상품/서비스 정보와 고지사항의
                안내, 상품/서비스 구매에 따른 혜택 제공
              </li>
              <li>
                4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간개인정보
                이용목적 달성 시까지 보존합니다. 단, 관계 법령의 규정에 의하여
                일정 기간 보존이 필요한 경우에는 해당 기간만큼 보관 후
                삭제합니다. 위 개인정보 제공 동의를 거부할 권리가 있으나, 거부
                시 네이버페이를 이용한 상품 및 디지털 콘텐츠 구매가
                불가능합니다.
              </li>
              <li>
                그 밖의 내용은 네이버페이 가맹점의 자체 이용약관 및
                개인정보처리방침에 따릅니다.
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Payment;
