import React, { useEffect, useRef, useState } from "react";
import styles from "./ProductDetail.module.css";
import Button from "../../components/ui/Button";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function RoutiAi() {
  const pdfUrl = "/pdf/ROUTi-AI_Brochure(Eng).pdf";
  const [hovered, setHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [faqToggleOpen1, setFaqToggleOpen1] = useState(false);
  const [faqToggleOpen2, setFaqToggleOpen2] = useState(false);
  const navigate = useNavigate();
  const target1 = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);
  const target4 = useRef(null);
  const target5 = useRef(null);
  const video1 = useRef(null); // 비디오 1 참조
  const video2 = useRef(null); // 비디오 2 참조
  const video3 = useRef(null); // 비디오 3 참조
  const video4 = useRef(null); // 비디오 4 참조
  const video5 = useRef(null); // 비디오 5 참조

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles["show"]);
        if (entry.target.tagName === "VIDEO") {
          entry.target.muted = true;
          entry.target.play(); // 비디오가 보일 때 재생
        }
      } else {
        entry.target.classList.remove(styles["show"]);
        if (entry.target.tagName === "VIDEO") {
          entry.target.pause(); // 비디오가 보이지 않으면 일시정지
        }
      }
    });
  };
  const options = {
    root: null,
    threshold: 0.5,
    rootMargin: "500px 0px 0px 0px",
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    [
      target1,
      target2,
      target3,
      target4,
      target5,
      video1,
      video2,
      video3,
      video4,
      video5,
    ].forEach((target) => {
      if (target.current) observer.observe(target.current);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className={styles.productDetailSection}>
      <div className={styles.mainImage}>
        <img src="/images/routiai/routiai_icon.png" alt="routiai" width={289} />
        <div className={styles.mainImage__text}>
          <div className={styles.mainImage__text__title}>
            <h1 className={styles.h1}>ROUTi-AI</h1>
          </div>
          <p>
            ROUTi-AI is an innovative solution for the automated design of
            mechanical, electrical, and plumbing pipe routing in large-scale
            factories. This BIM-based application offers high compatibility and
            scalability, providing an intuitive user-friendly UI. The solution
            addresses complex piping design challenges, preventing costly
            oversights in the construction and inefficiency in the maintenance
            cycle.
            <br />
            It uses visual scripting and algorithms to automatically generate
            optimal pipeline routes, avoiding interferences. The software
            quickly reroutes hundreds of pipes, offering options to minimize
            joints, reduce distance, or avoid interference. It calculates pipe
            and joint dimensions and produces PDF prototypes, enhancing design
            efficiency and accuracy.
            <br />
            ROUTi-AI integrates seamlessly as an add-in for Autodesk Revit,
            featuring an intuitive interface that syncs BIM data with a 3D
            Viewer.
          </p>
          {/* <p>
            ROUTi-AI is software that provides automated solutions for advanced
            MEP design processes. This BIM-based application offers high
            compatibility and scalability, by providing users with real-time
            visual information through user-friendly UI.
            <br />
            When two pipe connection points are specified, the software
            automatically generates the shortest possible pipeline route that
            avoids surrounding interferences using built in algorithms.
            Subsequently, the software calculates the dimensions of the pipes
            and joints and generates the final prototype, which can be saved as
            a PDF file.
            <br />
          </p> */}

          <div className={styles.buttonSection}>
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              <Button width="100%" height={70} text="Brochure" />
            </a>

            <div
              className={styles.buttonSection__comingsoon}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <Button
                width="100%"
                height={70}
                text={hovered ? "Coming Soon" : "Download"}
                primary
              />
            </div>
          </div>
        </div>
      </div>

      <video
        src="/images/routiai/routiai_home.mp4"
        className={styles.video}
        autoPlay
        loop
        playsInline
        muted
      />
      <div className={styles.imageDescription}>
        <div className={styles.imageDescription__row}>
          <div className={styles.imageDescription__image}>
            <video
              ref={video1}
              autoPlay
              loop
              playsInline
              src="/images/routiai/routiai_video1.mp4"
              alt="routiAI_video1"
              className={styles.imageDescription__image__video}
            />
          </div>
          <div ref={target1} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>BIM-based Application</h2>
            <p>
              Autodesk Revit add-in program is executed, the BIM information
              from Revit gets synchronized with the ROUTi-AI 3D Viewer screen.
              <br />
            </p>
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div ref={target2} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>3D Viewer</h2>
            <p>
              The BIM attribute information can be checked in
              <br /> real-time and provides navigation functions
              <br /> for the detailed BIM information.
              <br /> This provides an interface to control automation tools as
              well as generate routing information.
            </p>
          </div>
          <div className={styles.imageDescription__image}>
            <video
              ref={video2}
              width={700}
              autoPlay
              loop
              playsInline
              src="/images/routiai/routiai_video2.mp4"
              alt="routiAI_video2"
              className={styles.imageDescription__image__video}
            />
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div className={styles.imageDescription__image}>
            <video
              ref={video3}
              width={700}
              autoPlay
              loop
              playsInline
              src="/images/routiai/routiai_video3.mp4"
              alt="routiAI_video3"
              className={styles.imageDescription__image__video}
            />
          </div>
          <div ref={target3} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>Automatic Tool for Pipe Routing</h2>
            <p>
              Can set attribute information of piping systems and automatically
              establish routes based on the optimal path as determined by
              built-in algorithms.
            </p>
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div ref={target4} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>Automatic Tool for Hookup Routing</h2>
            <p>
              Automatically routes secondary piping systems based on the
              determined optimal path and provides real-time routing editing
              functions.
            </p>
          </div>
          <div className={styles.imageDescription__image}>
            <video
              ref={video4}
              width={700}
              autoPlay
              loop
              playsInline
              src="/images/routiai/routiai_video4.mp4"
              alt="routiAI_video1"
              className={styles.imageDescription__image__video}
            />
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div className={styles.imageDescription__image}>
            <video
              ref={video5}
              autoPlay
              loop
              playsInline
              src="/images/routiai/routiai_video5.mp4"
              alt="routiAI_video5"
              className={styles.imageDescription__image__video}
            />
          </div>
          <div ref={target5} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>
              3D Viewer to Revit Routing Information Synchronization
            </h2>
            <p>
              Automatically converts routing information exported in the Smart
              Routing AI 3D Viewer to BIM information, which can be imported to
              Autodesk Revit.
            </p>
          </div>
        </div>
      </div>
      <hr className={styles.hrDotted} />
      <div className={styles.advantages}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>Main Features</h2>
        <div className={styles.advantages__contents}>
          <div className={styles.advantages__contents__box}>
            <div className={styles.advantages__contents__box__image}>
              <img
                src="/images/routiai/routiaiAdvantages1.png"
                alt="routiaiAdvantages1"
              />
            </div>
            <div className={styles.advantages__contents__box__text}>
              ROUTi-AI sends Revit data to the viewport, which calculates the
              path data to map the BIM information.
            </div>
          </div>
          <div className={styles.advantages__contents__box}>
            <div className={styles.advantages__contents__box__image}>
              <img
                src="/images/routiai/routiaiAdvantages2.png"
                alt="routiaiAdvantages2"
              />
            </div>
            <div className={styles.advantages__contents__box__text}>
              ROUTi-AI generates paths through runtime processing and sends the
              computational results to the Revit Add-in interface.
            </div>
          </div>
          <div className={styles.advantages__contents__box}>
            <div className={styles.advantages__contents__box__image}>
              <img
                src="/images/routiai/routiaiAdvantages3.png"
                alt="routiaiAdvantages3"
              />
            </div>
            <div className={styles.advantages__contents__box__text}>
              Useful for professionals such as MEP designers, civil engineers,
              and architectural designers.
            </div>
          </div>
        </div>
      </div>
      <hr className={styles.hrDotted} />
      <div className={styles.awards}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>
          Awards and Certifications
        </h2>
        <div className={styles.awards__contents}>
          <div className={styles.awards__contents__box}>
            <div className={styles.awards__contents__box__image}>
              <img src="/images/awards/Edison2023.png" alt="Edison2023" />
            </div>
            <div className={styles.awards__contents__box__text}>
              <p>The most prestigious invention award in the United States.</p>
              <h3 className={styles.h2}>Edison Awards, 2023</h3>
              <p className={styles.awards__contents__box__text_color}>
                In honor of Thomas Edison's innovative spirit, the Edison
                Awards, often referred to as the "Oscars of Innovation," have
                been held since 1987. It is the most prestigious invention award
                in the United States.
              </p>
            </div>
          </div>
          <div className={styles.awards__contents__box}>
            <div className={styles.awards__contents__box__image}>
              <img src="/images/awards/GS.png" alt="GS" />
            </div>
            <div className={styles.awards__contents__box__text}>
              <p>Software Quality Certification</p>
              <h3 className={styles.h2}>Achieved GS Certification, Grade 1.</h3>
              <p className={styles.awards__contents__box__text_color}>
                Certified with Grade 1 by testing and evaluating software
                functionality, performance efficiency, usability, reliability,
                and security in accordance with the Software Industry Promotion
                Act and the Ministry of Science and ICT notifications, meeting
                ISO international standards.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className={styles.hrDotted} />
      <div className={styles.installGuides}>
        <h2 className={styles.h1}>Service Guide</h2>
        <div className={styles.installGuides__contents}>
          <h3 className={styles.h2}>System Review</h3>
          <p>
            Refer to the system requirements to ensure that your computer meets
            the minimum system requirements. You should download and install the
            version compatible with your operating system (32-bit or 64-bit).
            While it is possible to install and use a 32-bit program on a 64-bit
            operating system, it is not recommended. Verify your operating
            system and download the appropriate version. The system requirements
            will specify the minimum or recommended specifications for your
            computer. For optimal performance, it is recommended to use a system
            that meets or exceeds the recommended specifications.
          </p>

          <h3 className={styles.h2}>Preparing an SLZ User Account</h3>

          <p>
            The SLZ program operates through web authentication via the
            internet. Therefore, to use the SLZ program and technical services
            smoothly, it is essential to register as a member on the site. On
            the user site, you can register, withdraw, modify information, and
            check contract information. The ID and password registered here can
            be used to authenticate the SLZ program or access all technical
            documents and services on this site (technical service site). In
            cases where internet use is difficult, such as due to company
            security restricting access to external internet networks or travel
            to areas with uncertain internet environments, support can be
            provided through a separate inquiry.
          </p>

          <h3 className={styles.h2}>Installation Preparation</h3>

          <p>
            Before installing the program, it is advisable to check the
            following items in advance.
            <br />
            <br />
            <b>1. Obtain Administrator Rights</b>
            <br />
            <br />
            Ensure you have administrator rights on the computer where you plan
            to install the software. This step can usually be skipped for
            personal computers. However, if you are using a computer within a
            company with enhanced security, you will need to verify with your IT
            department to gain local user management rights that allow the
            installation of new software. You can check for administrator rights
            by navigating to Control Panel > User Accounts > Manage User
            Accounts.
            <br />
            <br />
            <b>
              2. Install Windows Updates, Disable Antivirus and Firewall, and
              Close Related Applications
            </b>
            <br />
            <br />
            Installing Windows updates is an excellent way to address security
            issues and system errors. While it is challenging to determine if a
            specific Windows update is directly related to installation
            failures, updating your system can help resolve general security
            issues and errors, normalizing the computer’s condition. During
            installation, essential components might require registry additions
            or modifications, and the use of system folders/files. Sometimes,
            antivirus software and firewalls can interfere with these processes.
            Temporarily disabling your antivirus and firewall during
            installation might resolve these issues. If the SLZ program
            integrates with other software such as MS Office, it is advisable to
            close those applications to prevent any interference during
            installation.
          </p>
        </div>
      </div> */}
      <hr className={styles.hrDotted} />
      <div className={styles.systemRequirement}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>
          System Requirements
        </h2>
        <div className={styles.systemRequirement__contents}>
          <table className={styles.systemRequirement__table}>
            <tbody>
              <tr>
                <th>Operating System</th>
                <td>Microsoft Windows 10, 11</td>
              </tr>

              <tr>
                <th>Memory</th>
                <td>8GB​</td>
              </tr>
              <tr>
                <th>Revit​</th>
                <td>Autodesk Revit 2021, 2022​</td>
              </tr>
              <tr>
                <th>Graphics</th>
                <td>
                  A DirectX® 11 compatible graphics card with Shader Model 5 and
                  a minimum of 4GB of video memory.
                </td>
              </tr>
              <tr>
                <th>Disk Space</th>
                <td>30 GB of available disk space</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr className={styles.hrDotted} />
      <div className={styles.faq}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>FAQ</h2>
        <div className={styles.faq__contents}>
          <hr className={styles.hr} />
          <div className={styles.faq__contents__text}>
            <div
              className={styles.faq__contents__text_titleSection}
              onClick={() => setFaqToggleOpen1((prevState) => !prevState)}
            >
              <div className={styles.faq__contents__text_title}>
                What are the differences between ROUTi-AR and AI?
              </div>

              {faqToggleOpen1 ? <IoIosArrowUp /> : <IoIosArrowForward />}
            </div>
            {faqToggleOpen1 && (
              <p>
                ROUTi-AR uses tablets and VR devices to visualize and optimize
                piping routes in real-time on-site. In contrast, ROUTi-AI is a
                PC-based software focused on automating and accelerating piping
                design for large-scale plants. Both improve accuracy and
                efficiency but serve different purposes and project scopes.
              </p>
            )}
          </div>
          <hr className={styles.hr} />
          <div className={styles.faq__contents__text}>
            <div
              className={styles.faq__contents__text_titleSection}
              onClick={() => setFaqToggleOpen2((prevState) => !prevState)}
            >
              <div className={styles.faq__contents__text_title}>
                How much time does it save in design?
              </div>
              {faqToggleOpen2 ? <IoIosArrowUp /> : <IoIosArrowForward />}
            </div>

            {faqToggleOpen2 && (
              <p>
                ROUTi-AI automates complex piping designs, reducing design time
                by over 50%. It can handle more than 1,000 piping lines
                simultaneously, making the process highly efficient. This
                supports significant time savings and reduces design errors
                compared to traditional methods.
              </p>
            )}
          </div>

          <hr className={styles.hr} />
          <div className={styles.buttonSection1}>
            <Button
              width={180}
              height={60}
              text="View all FAQ"
              onClick={() => navigate("/faq")}
            />
          </div>
        </div>
      </div>

      <div className={styles.bannerSection__contents}>
        <div>
          Experience ROUTi-AI, now available for immediate use in Autodesk
          Revit.
        </div>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Button
            className={styles.backgroundButton}
            width="260px"
            height="70px"
            border={isHovered ? "var(--color-accent)" : "white"}
            color={isHovered ? "var(--color-accent)" : "transparent"}
            fontColor="white"
            text={isHovered ? "Coming Soon" : "Download"}
          />
        </div>
      </div>
    </div>
  );
}
export default RoutiAi;
