import React, { useState } from "react";
import styles from "./FindId.module.css";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";

function FindId() {
  const navigate = useNavigate();
  const [authTrue, setAuthTrue] = useState(false);
  const handleAuthCheck = () => {
    setAuthTrue(!authTrue);
  };
  return (
    <div className={styles.findId}>
      <div className={styles.findIdTitle}>Find ID</div>
      {!authTrue ? (
        <div className={styles.loginContents}>
          <div className={styles.loginContentsAuth}>
            <p>
              Authenticate with the email registered in your member information
            </p>
            <div className={styles.emailSection}>
              <input
                className={styles.loginContentsInput}
                type="email"
                placeholder="Enter your email to receive a verification email."
              />
              <Button width="200px" height="50px" text="Receive Email"></Button>
            </div>
            <input
              className={styles.loginContentsInput}
              type="text"
              placeholder="Enter the verification code received via email."
            />
          </div>
          <div className={styles.loginContentsButton}>
            <Button
              width="100%"
              height="50px"
              text="Verify"
              primary
              onClick={handleAuthCheck}
            ></Button>
          </div>
        </div>
      ) : (
        <div className={styles.foundId}>
          <p>Your registered ID is as follows</p>
          <div className={styles.idSection}>
            <p className={styles.id}>gildong_hong</p>
            <span>(Registered on 2024.2.21)</span>
          </div>
          <div className={styles.buttonSection}>
            <Button
              width="100%"
              height="50px"
              text="Find Password"
              onClick={() => navigate("/findpassword")}
            />
            <Button
              width="100%"
              height="50px"
              text="Login"
              primary
              onClick={() => navigate("/login")}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default FindId;
