import React from "react";
import styles from "./DeletionComplete.module.css";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";

function DeletionComplete() {
  const navigate = useNavigate();
  return (
    <div className={styles.deletionCompleteSection}>
      <h2 className={styles.title}>
        Your account has been successfully deleted.
      </h2>
      <p>
        Thank you for using SLZ Support.
        <br />
        We will always do our best to provide better services by taking your
        feedback into account.
      </p>
      <div className={styles.buttonSection}>
        <Button
          width="300px"
          height="50px"
          text="Return to Home Screen"
          onClick={() => navigate("/")}
          primary
        />
      </div>
    </div>
  );
}

export default DeletionComplete;
