import React, { useState } from "react";
import styles from "./FindPassword.module.css";
import Button from "../../components/ui/Button";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/ui/Modal";
import { useForm, useWatch } from "react-hook-form";

function FindPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitted, errors },
    trigger,
  } = useForm();
  const handleBlur = (field) => {
    trigger(field);
  };
  const [authTrue, setAuthTrue] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleAuthCheck = () => {
    setAuthTrue(!authTrue);
  };
  const handleChangePassword = () => {
    setModalOpen(true);
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };
  return (
    <div className={styles.findPassword}>
      <div className={styles.findPasswordTitle}>Find Password</div>
      {!authTrue ? (
        <div className={styles.passwordContents}>
          <div className={styles.passwordContentsAuth}>
            <p>
              Authenticate with the email registered in your member information
            </p>
            <input
              className={styles.passwordContentsInput}
              type="text"
              placeholder="Please enter your ID"
            />
            <div className={styles.emailSection}>
              <input
                className={styles.passwordContentsInput}
                type="email"
                placeholder="Enter your email to receive a verification email."
              />
              <Button width="200px" height="50px" text="Receive Email"></Button>
            </div>
            <input
              className={styles.passwordContentsInput}
              type="text"
              placeholder="Enter the verification code received via email."
            />
          </div>
          <div className={styles.passwordContentsButton}>
            <Button
              width="100%"
              height="50px"
              text="Verify"
              primary
              onClick={handleAuthCheck}
            ></Button>
          </div>
        </div>
      ) : (
        <div className={styles.passwordContents}>
          <div className={styles.passwordContentsAuth}>
            <p>Reset Password</p>
            <input
              className={styles.passwordContentsInput}
              type="password"
              id="password"
              placeholder="Please enter your password (combination of numbers, letters, and special characters, at least 8 characters)"
              aria-invalid={
                isSubmitted ? (errors.password ? "true" : "false") : undefined
              }
              {...register("password", {
                required: "Password is required.",
                pattern: {
                  value:
                    /^(?=.*[a-zA-Z])(?=.*[?!@#$%^*+=-])(?=.*[0-9]).{8,16}$/,
                  message:
                    "Please enter a combination of numbers, letters, and special characters, at least 8 characters.",
                },
              })}
              onBlur={() => handleBlur("password")}
            />
            {errors.password && (
              <div className={styles.validation}>
                <p>
                  <IoAlertCircleOutline />
                  {errors.password.message}
                </p>
              </div>
            )}
            <input
              className={styles.passwordContentsInput}
              type="password"
              id="passwordConfirm"
              placeholder="Please re-enter your password (combination of numbers, letters, and special characters, at least 8 characters)"
              aria-invalid={
                isSubmitted
                  ? errors.passwordConfirm
                    ? "true"
                    : "false"
                  : undefined
              }
              {...register("passwordConfirm", {
                required: "Please confirm your password.",
                validate: {
                  check: (val) => {
                    if (getValues("password") !== val) {
                      return "Passwords do not match.";
                    }
                  },
                },
              })}
              onBlur={() => handleBlur("passwordConfirm")}
            />
            <div className={styles.validation}>
              <p>
                {errors.passwordConfirm && (
                  <>
                    <IoAlertCircleOutline />
                    {errors.passwordConfirm.message}
                  </>
                )}
              </p>
            </div>
          </div>

          <Button
            width="100%"
            height="50px"
            text="Temporary Button"
            onClick={handleSubmit((data) => alert(JSON.stringify(data)))}
          />
          <Button
            width="100%"
            height="50px"
            text="Change Password"
            primary
            onClick={handleChangePassword}
          />
        </div>
      )}
      {modalOpen && (
        <Modal text="Your password has been changed" pageName="Login" />
      )}
    </div>
  );
}

export default FindPassword;
