import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import styles from "./NoticeDetail.module.css";
import Button from "../../components/ui/Button";
import { deleteNews, fetchNews } from "../../api/crud"; // fetchNews 함수 가져오기

function NoticeDetail() {
  const { id } = useParams(); // URL에서 ID 가져오기
  const postId = parseInt(id, 10); // id 값을 숫자로 변환
  const navigate = useNavigate();

  // 게시글 정보를 상태로 관리
  const [totalPosts, setTotalPosts] = useState(0);
  const [post, setPost] = useState(null);

  useEffect(() => {
    const loadPost = async () => {
      try {
        const allNews = await fetchNews(); // 모든 뉴스 데이터 가져오기
        setTotalPosts(allNews.length);
        const fetchedPost = allNews.find((news) => news.id === id); // ID를 문자열로 비교

        setPost(fetchedPost);
      } catch (error) {
        console.log(error);
      }
    };

    loadPost(); // 컴포넌트가 마운트되면 게시글 정보 로드
  }, [id]);

  const handleDeleteNews = async (id) => {
    try {
      await deleteNews(id);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.technicalAccount}>
      <div className={styles.technicalAccountContents}>
        <div className={styles.titleSection}>
          <h1 className={styles.title}>{post.title}</h1>
          <span>| {post.type}</span>
        </div>
        <div className={styles.date}>{post.date}</div>
        <hr className={styles.hr} />
        <div className={styles.image}>
          <img
            src={post.images ? post.images : "/images/logo.png"}
            alt="news_image"
          />
        </div>
        <div className={styles.contents}>
          <p>{post.contents}</p>
        </div>
        <hr className={styles.hr} />
        <div className={styles.postButton}>
          <div className={styles.postButton}>
            {postId > 1 ? (
              <Link to={`/notice/${postId - 1}`}>
                <Button text="<Prev" />
              </Link>
            ) : (
              <Button text="<Prev" disabled />
            )}

            {postId < totalPosts ? (
              <Link to={`/notice/${postId + 1}`}>
                <Button text="Next>" />
              </Link>
            ) : (
              <Button text="Next>" />
            )}

            <Link to="/notice">
              <Button text="List" primary />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeDetail;
