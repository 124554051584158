import React, { useEffect, useState } from "react";
import styles from "./NoticeEdit.module.css";
import Button from "../../components/ui/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { updateNews } from "../../api/crud";

function NoticeEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const [news, setNews] = useState(location.state);

  useEffect(() => {
    console.log(news);
  }, [news]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNews((prevNews) => ({ ...prevNews, [name]: value }));
  };

  const handleUpdateNews = async () => {
    try {
      await updateNews(news.id, news);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.editSection}>
      <div className={styles.editSectionContents}>
        <div className={styles.titleSection}>
          <h1 className={styles.title}>Edit Post</h1>
          <span>| Notice</span>
        </div>
        <hr className={styles.hr} />
        <div className={styles.contentsSection}>
          <div className={styles.contentsSection__title}>
            <label htmlFor="title">
              Title
              <br />
              (Required)
            </label>
            <input
              type="text"
              id="title"
              name="title"
              placeholder="Please enter the title."
              required
              minLength="10"
              maxLength="100"
              value={news.title}
              onChange={handleChange}
            />
          </div>
          <div className={styles.contentsSection__type}>
            <div className={styles.contentsSection__type__title}>
              Type of Post
              <br />
              (Required)
            </div>
            <div className={styles.contentsSection__type__item}>
              <label htmlFor="Notice">
                <input
                  type="radio"
                  id="Notice"
                  name="type"
                  value="Notice"
                  checked={news.type === "Notice"}
                  onChange={handleChange}
                />
                Notice
              </label>
              <label htmlFor="ROUTi-AR">
                <input
                  type="radio"
                  id="ROUTi-AR"
                  name="type"
                  value="ROUTi-AR"
                  checked={news.type === "ROUTi-AR"}
                  onChange={handleChange}
                />
                ROUTi-AR
              </label>
              <label htmlFor="ROUTi-AI">
                <input
                  type="radio"
                  id="ROUTi-AI"
                  name="type"
                  value="ROUTi-AI"
                  checked={news.type === "ROUTi-AI"}
                  onChange={handleChange}
                />
                ROUTi-AI
              </label>
            </div>
          </div>
          <div className={styles.contentsSection__image}>
            <label htmlFor="image">
              Attach Image
              <br />
              (Optional)
            </label>
            <div className={styles.contentsSection__image__contents}>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/png, image/jpeg"
                // 파일입력로직 넣기
              />
            </div>
          </div>
          <div className={styles.contentsSection__contents}>
            <label htmlFor="contents">
              Content
              <br />
              (Required)
            </label>
            <textarea
              id="contents"
              name="content"
              placeholder="Please enter the content."
              required
              value={news.contents}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.postButton}>
          <Button text="Submit" primary onClick={handleUpdateNews} />
          <Button text="Cancel" onClick={() => navigate(-1)} />
        </div>
      </div>
    </div>
  );
}

export default NoticeEdit;
