import React, { useEffect, useState } from "react";
import styles from "./HomeCustomer.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import axios from "axios";

function HomeCustomer() {
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    axios
      .get("/db/home.json")
      .then((res) => {
        const awardData = res.data.awards[0];
        const loadedAwards = [];

        Object.keys(awardData).forEach((key) => {
          awardData[key].forEach((item) =>
            loadedAwards.push({
              contents: item.contents,
              title: item.title,
              solution: item.solution,
              image: item.image,
            })
          );
        });

        setAwards(loadedAwards);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <section className={styles.customerSection}>
      <div className={styles.homeCustomerBox}>
        <div className={styles.section2title}>
          <h1>Awards</h1>
        </div>
        <div className={styles.awards}>
          {awards.length > 0 ? ( // 슬라이드가 1개 이상일 때만 Swiper 사용
            <Swiper
              loop={awards.length > 1} // 슬라이드가 1개일 경우 loop 모드를 비활성화
              spaceBetween={100}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={awards.length > 1} // 슬라이드가 1개일 경우 네비게이션 비활성화
              style={{
                "--swiper-pagination-color": "gray",
                "--swiper-navigation-color": "gray",
                "--swiper-navigation-size": "30px",
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              {awards.map((award, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.awardsBox}>
                    <div className={styles.awardsBox_image}>
                      <img
                        src={award.image}
                        alt={award.title}
                        className={styles.images}
                      />
                    </div>
                    <div className={styles.awardsBox_text}>
                      <p style={{ color: "var(--color-accent)" }}>
                        {award.solution}
                      </p>
                      <h3>{award.title}</h3>
                      <p>{award.contents}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p>No awards available.</p> // 슬라이드가 없을 때 보여줄 메시지
          )}
        </div>
      </div>
    </section>
  );
}

export default HomeCustomer;
