import axios from "axios";
const apiUrl = "/db/news";
const faqUrl = "/db/faq";
const userUrl = "/db/user";
const deletedUserOpinionUrl = "/db/deleteduseropinion";

export const fetchNews = async () => {
  try {
    const response = await axios.get(`${apiUrl}.json`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const addNews = async (newItem) => {
  try {
    const response = await axios.post(apiUrl, newItem);
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};

export const updateNews = async (id, updatedItem) => {
  try {
    const response = await axios.put(`${apiUrl}/${id}`, updatedItem);
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const deleteNews = async (id) => {
  try {
    await axios.delete(`${apiUrl}/${id}`);
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

// FAQ
export const fetchFaq = async () => {
  try {
    const response = await axios.get(`${faqUrl}.json`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const addFaq = async (newItem) => {
  try {
    const response = await axios.post(faqUrl, newItem);
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};

export const updateFaq = async (id, updatedItem) => {
  try {
    const response = await axios.put(`${faqUrl}/${id}`, updatedItem);
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const deleteFaq = async (id) => {
  try {
    await axios.delete(`${faqUrl}/${id}`);
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

// USER DATA

export const fetchUser = async () => {
  try {
    const response = await axios.get(`${userUrl}.json`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//수정할 url 확인후 변경
export const updateUser = async (id, updatedItem) => {
  try {
    const response = await axios.put(`${userUrl}.json`, updatedItem);
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const addUser = async (newUser) => {
  try {
    const response = await axios.post(userUrl, newUser);
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await axios.delete(`${userUrl}/${id}`);
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const addDeletedUserOpinion = async (newOpinion) => {
  try {
    const response = await axios.post(deletedUserOpinionUrl, newOpinion);
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};
