import React, { useState, useEffect } from "react";
import styles from "./Search.module.css";
import { CiSearch } from "react-icons/ci";

function Search({ onSubmit, initialSearch }) {
  const [isInputClicked, setIsInputClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState(initialSearch || "");

  useEffect(() => {
    setSearchTerm(initialSearch);

    // initialSearch가 있을 때 0.5초 뒤 자동 제출
    if (initialSearch) {
      const timer = setTimeout(() => {
        onSubmit(initialSearch);
      }, 500);

      // 타이머 정리 (cleanup)
      return () => clearTimeout(timer);
    }
  }, [initialSearch, onSubmit]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(searchTerm);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        className={styles.input}
        name="filter"
        value={searchTerm}
        placeholder={isInputClicked ? "" : "Search"}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setIsInputClicked(true)}
        onBlur={() => setIsInputClicked(false)}
      />
      <button className={styles.searchButton}>
        <CiSearch />
      </button>
    </form>
  );
}

export default Search;
