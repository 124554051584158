import React from "react";
import styles from "./SolutionPlan.module.css";
import Button from "../../components/ui/Button";

import { IoIosCheckmark } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function SolutionPlan() {
  const navigate = useNavigate();
  return (
    <div className={styles.solutionPlanSection}>
      <div className={styles.titleSection}>
        <h1>콘버스 솔루션 요금제</h1>
        <p>디지털트윈 기술 콘버스의 모든 것을 끊김없이 이용하세요</p>
      </div>
      <div className={styles.planSection}>
        <div className={styles.planSection__box}>
          <h3>Basic</h3>
          <p>
            &#8361;<span className={styles.price}>1,980,000</span>/월
            <br />
            <span className={styles.color}>(30일 무료체험)</span>
          </p>
          <div className={styles.planSection__box__info}>
            <ul>
              <li>
                <IoIosCheckmark />
                정합도 보정 및 10회 증강 서비스
              </li>
              <li>
                <IoIosCheckmark />
                실시간 연동 및 마커 추가 기능
              </li>
              <li>&nbsp;</li>
            </ul>
            <ul>
              <li>서버용량: - </li>
              <li>서버접속 인원수 : 3인</li>
              <li> 사용시간 : 12시간</li>
            </ul>
          </div>
          <div className={styles.buttonSection}>
            <Button width="100%" height="50px" text="체험하기" primary />
          </div>
        </div>
        <div className={styles.planSection__box}>
          <h3>Standard</h3>
          <p>
            &#8361;<span className={styles.price}> 3,750,000</span>/월
            <br />
            <span className={styles.color}>&nbsp;</span>
          </p>
          <div className={styles.planSection__box__info}>
            <ul>
              <li>
                <IoIosCheckmark />
                정합도 보정 및 10회 증강 서비스
              </li>
              <li>
                <IoIosCheckmark />
                실시간 연동 및 마커 추가 기능
              </li>
              <li>&nbsp;</li>
            </ul>
            <ul>
              <li>서버용량: - </li>
              <li>서버접속 인원수 : 3인</li>
              <li> 사용시간 : 12시간</li>
            </ul>
          </div>
          <div className={styles.buttonSection}>
            <Button
              width="100%"
              height="50px"
              color="var(--color-lightGray)"
              fontColor="var(--color-primary)"
              text="결제하기"
              onClick={() => navigate("/payment")}
            />
          </div>
        </div>
        <div className={styles.planSection__box}>
          <h3>Custom</h3>
          <p>
            &#8361;<span className={styles.price}>2,540,000</span>/월
            <br />
            (1회 SI구축비용 99백만원)
          </p>
          <div className={styles.planSection__box__info}>
            <ul>
              <li>
                <IoIosCheckmark />
                이슈 체크 실시간 연동
              </li>
              <li>
                <IoIosCheckmark />
                원격 기술지원
              </li>
              <li>
                <IoIosCheckmark />
                버전 업그레이드
              </li>
            </ul>
            <ul>
              <li>서버용량: 별도협의</li>
              <li>서버접속 인원수 : 별도협의</li>
              <li>사용시간 : 24시간</li>
            </ul>
          </div>
          <div className={styles.buttonSection}>
            <Button
              width="100%"
              height="50px"
              color="var(--color-lightGray)"
              fontColor="var(--color-primary)"
              text="결제하기"
              onClick={() => navigate("/payment")}
            />
          </div>
        </div>
      </div>
      <div className={styles.planDetailSection}>
        <table className={styles.table}>
          <tr>
            <th>요금제</th>
            <th>판매가격 (1개월)</th>
            <th>주요 기본서비스</th>
            <th>사용환경</th>
          </tr>
          <tr>
            <th>Basic</th>
            <td>
              &#8361;1,980,000
              <br />
              (30일 무료이용)
            </td>
            <td>
              <ul>
                <li>정합도 보정 및 10회 증강 서비스</li>
                <li>실시간 연동 및 마커 추가 기능</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>서버용량: -</li>
                <li>서버접속 인원수 : 3인</li>
                <li>사용시간 : 12시간</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>Standard</th>
            <td>&#8361;3,750,000</td>
            <td>
              <ul>
                <li>정합도 보정 및 10회 증강 서비스</li>
                <li>실시간 연동 및 마커 추가 기능</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>서버용량: 500G</li>
                <li>서버접속 인원수 : 9인 (인원추가 별도 문의)</li>
                <li>사용시간 : 12시간</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>Custom</th>
            <td>
              &#8361;2,540,000
              <br />
              (1회 SI구축비용 99백만원)
            </td>
            <td>
              <ul>
                <li>이슈 체크 실시간 연동</li>
                <li>원격 기술지원</li>
                <li>버전 업그레이드</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>서버용량: 별도협의</li>
                <li>서버접속 인원수 : 별도협의</li>
                <li>사용시간 : 24시간</li>
                <li>원격기술지원 (원상복구, 방문 시 별도 비용)</li>
                <li>버전 업그레이드 : 별도협의</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <div className={styles.noticeSection}>
        <div className={styles.noticeSection__title}>
          <IoIosCheckmark />
          <h3>결제 시 확인사항</h3>
        </div>
        <div className={styles.noticeSection__contents}>
          <ul>
            <li>
              각 요금제에서 1일은 24시간, 1주일은 7일, 1개월은 30일, 3개월은
              90일, 1년은 365일 기준입니다.
            </li>
            <li>
              상기 요금제는 1인 1계정 기준이며, 복수계정 가입 시 현재 요금제에서
              배액을 적용합니다.
            </li>
            <li>
              사용자는 ID와 비밀번호를 타인과 공유하여 사용할 수 없으며, 동일
              아이디의 중복 로그인은 지원하지 않습니다.
            </li>
            <li>
              상기 요금제는 1년 계약 할인 요금제로 매월 자동결제되며 1년 단위로
              해지가 가능하며 중도해지의 경우 위약금이 발생할 수 있습니다.
            </li>
            <li>
              견적서 및 사업자 등록증 사본은 결제완료 후 '결제정보' 화면에서
              직접 다운로드 가능합니다.
            </li>
          </ul>
        </div>
        <p>
          서울보증보험을 통한 이행(지급) 보증보험가입됨. 증권번호:
          100-000-0000000000000
        </p>
      </div>
      <div className={styles.bannerSection}>
        <div>
          결제나 솔루션 관련하여
          <br /> 궁금하신 사항이 있으신가요?
        </div>
        <Button
          width="250px"
          height="85px"
          color="transparent"
          fontColor="white"
          fontSize="24px"
          text="FAQ 바로가기"
          onClick={() => navigate("/faq")}
        />
      </div>
    </div>
  );
}

export default SolutionPlan;
