import React, { useState } from "react";
import styles from "./DeleteAccount.module.css";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { addDeletedUserOpinion } from "../../api/crud";

function DeleteAccount() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    reason: "",
  });

  const handleRadioChange = (e) => {
    setFormData({
      ...formData,
      reason: e.target.id,
    });
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const handleSubmit = async () => {
    try {
      await addDeletedUserOpinion(formData);
      navigate("/deletioncomplete");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.deleteAccount}>
      <form className={styles.deleteAccountSection} onSubmit={handleOpenModal}>
        <div>
          <h1 className={styles.heading1}>Delete Account</h1>
          <p>Please select the reason for deleting your account</p>
          <div className={styles.radioButtonSection}>
            <div className={styles.radioButtonSection__input}>
              <input
                type="radio"
                id="noSolutionNeeded"
                name="reason"
                onChange={handleRadioChange}
                checked={formData.reason === "noSolutionNeeded"}
              />
              <label htmlFor="noSolutionNeeded">
                No need to use the solution
              </label>
            </div>
            <div className={styles.radioButtonSection__input}>
              <input
                type="radio"
                id="unsatisfiedService"
                name="reason"
                onChange={handleRadioChange}
                checked={formData.reason === "unsatisfiedService"}
              />
              <label htmlFor="unsatisfiedService">
                Unsatisfied with the service
              </label>
            </div>
            <div className={styles.radioButtonSection__input}>
              <input
                type="radio"
                id="noDesiredInformation"
                name="reason"
                onChange={handleRadioChange}
                checked={formData.reason === "noDesiredInformation"}
              />
              <label htmlFor="noDesiredInformation">
                No desired information
              </label>
            </div>
            <div className={styles.radioButtonSection__input}>
              <input
                type="radio"
                id="privacyConcerns"
                name="reason"
                onChange={handleRadioChange}
                checked={formData.reason === "privacyConcerns"}
              />
              <label htmlFor="privacyConcerns">Privacy concerns</label>
            </div>
            <div className={styles.radioButtonSection__input}>
              <input
                type="radio"
                id="newAccountSignup"
                name="reason"
                onChange={handleRadioChange}
                checked={formData.reason === "newAccountSignup"}
              />
              <label htmlFor="newAccountSignup">
                To sign up with a new account
              </label>
            </div>
            <div className={styles.radioButtonSection__input}>
              <input
                type="radio"
                id="other"
                name="reason"
                onChange={handleRadioChange}
                checked={formData.reason === "other"}
              />
              <label htmlFor="other">Other</label>
            </div>
          </div>
        </div>
        <div className={styles.noticeSection}>
          <h3>Notice</h3>
          <p>Please check the following before deleting your account.</p>
          <ul>
            <li className={styles.li}>
              Even if you rejoin after deleting your SLZ Support account, member
              information, transaction information, etc. before deletion cannot
              be restored.
            </li>
            <li className={styles.li}>
              When you delete your account, all member information and service
              usage records will be deleted, and the deleted data cannot be
              recovered.
            </li>
            <li className={styles.li}>
              Customers using solution subscription services can delete their
              accounts after all service periods have ended.
            </li>
            <li className={styles.li}>
              However, in cases where information must be retained by law or by
              company internal policies to prevent abuse of membership
              registration, fraudulent use of services, etc., information will
              be retained for a certain period even after account deletion.
            </li>
            <li className={styles.li}>
              For more details, please check the SLZ Support Privacy Policy.
            </li>
          </ul>
        </div>
        <div className={styles.checkboxSection}>
          <input type="checkbox" id="agreewithDelete" required />
          <label htmlFor="agreewithDelete">
            I have checked all the notices and agree to the deletion of member
            information, transaction information, and usage records upon account
            deletion.
          </label>
        </div>
        <div className={styles.buttonSection}>
          <Button
            width="140px"
            height="50px"
            text="Delete Account"
            primary
            type="submit" // 폼 제출 시 모달 열리게 설정
          />
          <Button width="140px" height="50px" text="Cancel" />
        </div>
      </form>
      {modalOpen && (
        <div className={styles.modalBackground}>
          <div className={styles.modalSection}>
            <h3 className={styles.title}>
              Are you sure you want to delete your account?
            </h3>
            <p>
              Customers using solution subscription services can delete their
              accounts after all service periods have ended.
            </p>
            <div className={styles.buttonSection1}>
              <Button
                width="140px"
                height="50px"
                text="Delete Account"
                primary
                onClick={handleSubmit}
              />
              <Button
                width="140px"
                height="50px"
                text="Cancel"
                onClick={() => setModalOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteAccount;
