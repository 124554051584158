import React from "react";
import HomeNews from "./HomeNews";
import HomeCustomer from "./HomeCustomer";
import HomeMainDownload from "./HomeMainDownload";
import HomeSearch from "./HomeSearch";
import styles from "./Home.module.css";

function Home() {
  return (
    <div className={styles.home}>
      <HomeMainDownload />
      <HomeSearch />
      <HomeNews pages="notice" line={2} />
      <HomeCustomer />
    </div>
  );
}

export default Home;
