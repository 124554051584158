import styles from "./HomeNews.module.css";
import { useNavigate } from "react-router-dom";
import BoardComponents from "../../components/BoardComponents/BoardComponents";

function HomeNews({ pages, line }) {
  const navigate = useNavigate();
  return (
    <section className={styles.newsSection}>
      <div className={styles.newsTitle}>
        <h2 className={styles.heading1}>Latest Updates</h2>
        <div className={styles.arrow} onClick={() => navigate("/notice")}>
          Read More >
        </div>
      </div>
      <div className={styles.newsContents}>
        <BoardComponents pages={pages} line={line} />
      </div>
    </section>
  );
}

export default HomeNews;
