import React from "react";
import styles from "./Button.module.css";

function Button({
  text,
  icon,
  color,
  primary,
  fullPrimary,
  width = "100%",
  height,
  fontColor,
  onClick,
  fontSize,
  border,
}) {
  return (
    <button
      className={
        fullPrimary
          ? styles.buttonStyleFullPrimary
          : primary
          ? styles.buttonStylePrimary
          : styles.buttonStyle
      }
      style={{
        backgroundColor: color,
        borderColor: border,
        width,
        height,
        color: fontColor,
        fontSize,
      }}
      onClick={onClick}
    >
      {text}
      {icon}
    </button>
  );
}

export default Button;
