import React from "react";
import styles from "./Login.module.css";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";

function Login() {
  // const navigate = useNavigate();
  return (
    <div className={styles.login}>
      <div className={styles.loginSection}>
        <div className={styles.loginTitle}>LOGIN</div>
        <div className={styles.loginContents}>
          <div className={styles.loginContentsAuth}>
            <input
              className={styles.loginContentsInput}
              type="text"
              placeholder="Please enter your ID."
            />
            <input
              className={styles.loginContentsInput}
              type="password"
              placeholder="Please enter your Password."
            />
          </div>
          <div className={styles.loginContentsButton}>
            <Button width="100%" height="50px" text="LOGIN" primary></Button>
            <Button
              width="100%"
              height="50px"
              text="Sign Up"
              // onClick={() => navigate("/signup")}
            ></Button>
          </div>
        </div>
        <div className={styles.findAuth}>
          Find ID | Find Password
          {/* <Link to="/findid">Find ID</Link> |{" "}
          <Link to="/findpassword">Find Password</Link> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
