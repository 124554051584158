import React, { useState } from "react";
import styles from "./CompletePayment.module.css";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";

function CompletePayment() {
  const navigate = useNavigate();
  const [withoutPassbook, setWithoutPassbook] = useState(false);
  return (
    <>
      <button onClick={() => setWithoutPassbook(false)}>
        신용카드,간편결제,가상계좌
      </button>
      <button onClick={() => setWithoutPassbook(true)}>무통장입금</button>
      {!withoutPassbook ? (
        <div className={styles.completePaymentSection}>
          <IoIosCheckmarkCircleOutline />
          <h1>결제가 완료되었습니다</h1>
          <p>2024.4.28. 주문하신 상품의 주문번호는 20240428-0006235 입니다</p>
          <div className={styles.buttonSection}>
            <Button
              text="주문 상세보기"
              width={300}
              height={50}
              primary
              onClick={() => navigate("/orderdetail")}
            />
            <Button
              text="제품 다시보기"
              width={300}
              height={50}
              onClick={() => navigate("/solutionplan")}
            />
          </div>
        </div>
      ) : (
        <div className={styles.completePaymentSection}>
          <IoIosCheckmarkCircleOutline />
          <h1>주문이 완료되었습니다</h1>
          <p>2024.4.28. 주문하신 상품의 주문번호는 20240428-0006235 입니다</p>

          <div className={styles.depositInfo}>
            <p>2024.4.30 13:00 까지 입금 하시면 주문이 확정됩니다</p>
            <table className={styles.table}>
              <tr>
                <th>입금은행</th>
                <td>기업은행</td>
              </tr>
              <tr>
                <th>예금주</th>
                <td>주식회사 에스엘즈</td>
              </tr>
              <tr>
                <th>계좌번호</th>
                <td>0000-0000-000000</td>
              </tr>
              <tr>
                <th>입금자명</th>
                <td>홍길동</td>
              </tr>
            </table>
          </div>
          <div className={styles.buttonSection}>
            <Button
              text="주문 상세보기"
              width={300}
              height={50}
              primary
              onClick={() => navigate("/orderdetail")}
            />
            <Button
              text="제품 다시보기"
              width={300}
              height={50}
              onClick={() => navigate("/solutionplan")}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CompletePayment;
