import React from "react";
import styles from "./Modal.module.css";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

function Modal({ text, pageName }) {
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalSection}>
        <IoIosCheckmarkCircleOutline />
        <p className={styles.title}>{text}</p>
        <span>(Returning to the {pageName} page in 3 seconds.)</span>
      </div>
    </div>
  );
}

export default Modal;
