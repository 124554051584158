import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RoutiAr from "./pages/solutionDownload/RoutiAr";
import RoutiAi from "./pages/solutionDownload/RoutiAi";
import Faq from "./pages/faq/Faq";
import FaqWrite from "./pages/faq/FaqWrite";
import FaqEdit from "./pages/faq/FaqEdit";
import FaqDetail from "./pages/faq/FaqDetail";
import Notice from "./pages/notice/Notice";
import NoticeDetail from "./pages/notice/NoticeDetail";
import NoticeWrite from "./pages/notice/NoticeWrite";
import NoticeEdit from "./pages/notice/NoticeEdit";
import NotFound from "./pages/notFound/NotFound";
import Home from "./pages/home/Home";
import Solution from "./pages/solutionDownload/Solution";
import SolutionPlan from "./pages/payment/SolutionPlan";
import Payment from "./pages/payment/Payment";
import CompletePayment from "./pages/payment/CompletePayment";
import OrderDetail from "./pages/payment/OrderDetail";
import Login from "./pages/login/Login";
import MyPage, {
  MemberInfo,
  SecuritySetting,
  Ticket,
} from "./pages/mypage/MyPage";
import FindId from "./pages/login/FindId";
import FindPassword from "./pages/login/FindPassword";
import TermsofUse from "./pages/policy/TermsofUse";
import SignUp from "./pages/login/SignUp";
import DeleteAccount from "./pages/mypage/DeleteAccount";
import DeletionComplete from "./pages/mypage/DeletionComplete";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "solution",
        element: <Solution />,
        children: [
          { index: true, element: <RoutiAi /> },
          { path: "/solution/routiar", element: <RoutiAr /> },
          { path: "/solution/routiai", element: <RoutiAi /> },
        ],
      },

      { path: "faq", element: <Faq /> },
      { path: "faq/:id", element: <FaqDetail /> },
      { path: "faq/write", element: <FaqWrite /> },
      { path: "faq/:id/edit", element: <FaqEdit /> },
      { path: "notice", element: <Notice /> },
      { path: "notice/:id", element: <NoticeDetail /> },
      { path: "notice/write", element: <NoticeWrite /> },
      { path: "notice/:id/edit", element: <NoticeEdit /> },
      { path: "solutionplan", element: <SolutionPlan /> },
      { path: "payment", element: <Payment /> },
      { path: "completepayment", element: <CompletePayment /> },
      { path: "orderdetail", element: <OrderDetail /> },
      { path: "login", element: <Login /> },
      {
        path: "mypage",
        element: <MyPage />,
        children: [
          { index: true, element: <MemberInfo /> },
          { path: "/mypage/memberinfo", element: <MemberInfo /> },
          { path: "/mypage/ticket", element: <Ticket /> },
          { path: "/mypage/securitysetting", element: <SecuritySetting /> },
        ],
      },
      { path: "findid", element: <FindId /> },
      { path: "findpassword", element: <FindPassword /> },
      { path: "signup", element: <SignUp /> },
      { path: "termsofuse", element: <TermsofUse /> },
      { path: "deleteaccount", element: <DeleteAccount /> },
      { path: "deletioncomplete", element: <DeletionComplete /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
