import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./HomeMainDownload.module.css";
import Button from "../../components/ui/Button";
import axios from "axios";

function HomeMainDownload() {
  const [hovered, setHovered] = useState(false);
  const [showVideoIndex, setShowVideoIndex] = useState(null);
  const [solutions, setSolutions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/db/home.json")
      .then((res) => {
        const solutionData = res.data.solution[0];
        const loadedSolutions = Object.keys(solutionData).flatMap((key) =>
          solutionData[key].map((item) => ({
            title: item.title,
            subTitle: item.subTitle,
            icon: item.icon,
            file: item.file,
            url: item.url,
            video: item.video,
          }))
        );
        setSolutions(loadedSolutions);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleShowVideo = (index) => {
    setShowVideoIndex(index === showVideoIndex ? null : index);
  };

  return (
    <section className={styles.solutionSection}>
      <div className={styles.solutionTitle}>
        <h2 className={styles.heading1}>Product</h2>
        <div
          className={styles.arrow}
          onClick={() => navigate("/solution/routiai")}
        >
          View More &gt;
        </div>
      </div>
      <div className={styles.solutionContents}>
        {solutions.map((solution, index) => (
          <div className={styles.solutionBox} key={index}>
            <Link to={solution.url}>
              <div className={styles.solution}>
                <img
                  src={solution.icon}
                  alt={solution.title}
                  width={200}
                  className={styles.solution_images}
                />
                {showVideoIndex === index && (
                  <video
                    preload="none"
                    src={solution.video}
                    className={index === 1 ? styles.video1 : styles.video}
                    autoPlay
                    playsInline
                    muted
                    onClick={() => setShowVideoIndex(null)}
                  />
                )}
                <hr className={styles.hr} />
                <div className={styles.cardBox}>
                  <div
                    className={styles.mainImage__text__title__video}
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowVideo(index);
                    }}
                  >
                    {showVideoIndex === index ? "Close Player" : "Watch Video"}
                  </div>
                  <p className={styles.cardTitle}>{solution.title}</p>
                  <p className={styles.cardSubtitle}>{solution.subTitle}</p>
                </div>
              </div>
            </Link>
            {solution.title === "ROUTi-AR" ? (
              <Link
                to="https://apps.autodesk.com/RVT/en/Detail/Index?id=5859382366253003207&appLang=en&os=Win64"
                target="_blank"
              >
                <Button text="Download" fullPrimary />
              </Link>
            ) : (
              <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <Button
                  text={hovered ? "Coming Soon" : "Download"}
                  fullPrimary
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default HomeMainDownload;
