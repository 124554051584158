import React, { useState } from "react";
import styles from "./HomeSearch.module.css";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function HomeSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [faqToggleOpen1, setFaqToggleOpen1] = useState(false);
  const [faqToggleOpen2, setFaqToggleOpen2] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate("/faq", { state: { search: searchTerm } });
    }
  };

  return (
    <section className={styles.homeSearchSection}>
      <div className={styles.divideSection}>
        <div className={styles.searchSection}>
          <div className={styles.heading1}>FAQ</div>
          <div className={styles.searchBar}>
            <form className={styles.form} onSubmit={handleSearch}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.input}
                placeholder="Enter your inquiry."
              />
              <button type="submit" className={styles.searchButton}>
                <CiSearch />
              </button>
            </form>
          </div>
        </div>
        <div className={styles.faqSection}>
          <hr className={styles.hr} />
          <div className={styles.faqContents}>
            <div
              className={styles.faqTitleSection}
              onClick={() => setFaqToggleOpen1((prevState) => !prevState)}
            >
              <div className={styles.faqTitle}>
                What are the differences between ROUTi-AR and AI?
              </div>
              {faqToggleOpen1 ? <IoIosArrowUp /> : <IoIosArrowForward />}
            </div>
            {faqToggleOpen1 && (
              <p>
                ROUTi-AR uses tablets and VR devices to visualize and optimize
                piping routes in real-time on-site. <br />
                In contrast, ROUTi-AI is a PC-based software focused on
                automating and accelerating piping design for large-scale
                plants. Both improve accuracy and efficiency but serve different
                purposes and project scopes.
              </p>
            )}
          </div>
          <hr className={styles.hr} />
          <div className={styles.faqContents}>
            <div
              className={styles.faqTitleSection}
              onClick={() => setFaqToggleOpen2((prevState) => !prevState)}
            >
              <div className={styles.faqTitle}>
                Can ROUTi-AR and AI be customized?
              </div>
              {faqToggleOpen2 ? <IoIosArrowUp /> : <IoIosArrowForward />}
            </div>
            {faqToggleOpen2 && (
              <p>
                ROUTi-AI automates complex piping designs, reducing design time
                by over 50%.
                <br /> It can handle more than 1,000 piping lines
                simultaneously, making the process highly efficient. This
                supports significant time savings and reduces design errors
                compared to traditional methods.
              </p>
            )}
          </div>
          <hr className={styles.hr} />
        </div>
      </div>
    </section>
  );
}

export default HomeSearch;
