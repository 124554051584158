import React, { useEffect, useRef, useState } from "react";
import styles from "./ProductDetail.module.css";
import Button from "../../components/ui/Button";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

function RoutiAr() {
  const pdfUrl = "/pdf/ROUTi-AR_Brochure(Eng).pdf";
  const [isHovered, setIsHovered] = useState(false);
  const [faqToggleOpen1, setFaqToggleOpen1] = useState(false);
  const [faqToggleOpen2, setFaqToggleOpen2] = useState(false);
  const navigate = useNavigate();
  const target1 = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);
  const target4 = useRef(null);
  const target5 = useRef(null);
  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles["show"]);
      } else {
        entry.target.classList.remove(styles["show"]);
      }
    });
  };
  const options = {
    root: null,
    threshold: 0.5,
    rootMargin: "500px 0px 0px 0px",
  };
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    [target1, target2, target3, target4, target5].forEach((target) => {
      if (target.current) observer.observe(target.current);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className={styles.productDetailSection}>
      <div className={styles.mainImage}>
        <img src="/images/routiar/routiar_icon.png" alt="routiar" width={289} />
        <div className={styles.mainImage__text}>
          <div className={styles.mainImage__text__title}>
            <h1 className={styles.h1}>ROUTi-AR</h1>
          </div>
          <p>
            ROUTi-AR is an advanced AR (Augmented Reality) solution designed to
            optimize piping routes in MEP (Mechanical, Electrical, and Plumbing)
            systems during plant hook-up construction processes. The solution
            leverages MEP BIM data to generate real-time optimal piping routes
            using AR devices, ensuring precise and efficient on-site execution.
            The system automatically avoids clashes and generates the shortest
            piping routes, enhancing both accuracy and productivity during field
            operations.
            <br />
            Using AR devices (such as Android Tablet PC, Hololens 2), ROUTi-AR
            enables real-time augmentation of plant BIM data, allowing for
            preemptive review of design errors and quick responses to design
            changes. In MEP equipment and piping hook-up processes, it
            eliminates the need for traditional shop-drawing procedures.
            Instead, optimal piping routes are generated and reviewed on-site,
            with the generated pipes seamlessly integrated into BIM data. This
            data is compatible with pre-defined Family-Libraries in Autodesk
            Revit.
          </p>
          <div className={styles.buttonSection}>
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              <Button width="100%" height={70} text="Brochure" />
            </a>
            <Link
              to="https://apps.autodesk.com/RVT/en/Detail/Index?id=5859382366253003207&appLang=en&os=Win64"
              target="_blank"
            >
              <div className={styles.buttonSection__comingsoon}>
                <Button width="100%" height={70} text="Download" primary />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <video
        src="/images/routiar/routiar_home.mp4"
        className={styles.video}
        autoPlay
        playsInline
        loop
        muted
      />
      <div className={styles.imageDescription}>
        <div className={styles.imageDescription__row}>
          <div className={styles.imageDescription__image}>
            <img
              src="/images/routiar/routiar_image1.png"
              alt="routiar_image1"
              width={289}
            />
          </div>
          <div ref={target1} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>Pre-AR Review Function</h2>
            <p>
              Piping routes can be augmented via AR devices prior to Piping
              routes can be augmented via AR devices prior to construction to
              verify installation positions and orientations. The system also
              allows for optimal route generation and review based on real-time
              site conditions, reducing construction time and addressing
              potential issues in advance.
            </p>
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div ref={target2} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>
              On-Site BIM Data Access Without Information Loss
            </h2>
            <p>
              AR-augmented BIM data provides real-time on-site access to
              equipment and system components' geometry and attributes
              information on-site.
            </p>
          </div>
          <div className={styles.imageDescription__image}>
            <img
              src="/images/routiar/routiar_image2.png"
              alt="routiar_image2"
              width={289}
            />
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div className={styles.imageDescription__image}>
            <img
              src="/images/routiar/routiar_image3.png"
              alt="routiar_image3"
              width={289}
            />
          </div>
          <div ref={target3} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>
              BIM Data Extraction from AR-Generated Piping
            </h2>
            <p>
              Piping routes generated via AR can integrate seamlessly with
              Autodesk Revit for accurate BIM data extraction without
              information loss.
            </p>
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div ref={target4} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>Auto Routing</h2>
            <p>
              ROUTi-AR uses two proprietary algorithms, GE (Generative Elbow)
              and SE (Smart Elbow), to generate optimal routes within complex
              plant BIM data. These algorithms automatically avoid clashes and
              calculate the shortest possible routes. Users can adjust the elbow
              positioning along the X, Y, and Z axes, and the system
              recalculates the optimal path accordingly.
            </p>
          </div>
          <div className={styles.imageDescription__image}>
            <img
              src="/images/routiar/routiar_image4.png"
              alt="routiar_image4"
              width={289}
            />
          </div>
        </div>
        <div className={styles.imageDescription__row}>
          <div className={styles.imageDescription__image}>
            <img
              src="/images/routiar/routiar_image5.png"
              alt="routiar_image5"
              width={289}
            />
          </div>
          <div ref={target5} className={styles.imageDescription__text}>
            <h2 className={styles.h1}>Efficient On-Site Communication</h2>
            <p>
              By leveraging AR devices, users can review three-dimensional data
              on-site and facilitate real-time collaboration. This enables
              pre-planned construction strategies and piping route generation,
              allowing quick decision-making to save time and costs.
            </p>
          </div>
        </div>
      </div>
      <hr className={styles.hrDotted} />
      <div className={styles.advantages}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>
          Features and Advantages
        </h2>
        <div className={styles.advantages__contents}>
          <div className={styles.advantages__contents__box}>
            <div className={styles.advantages__contents__box__image}>
              <img
                src="/images/routiar/routiarAdvantages1.png"
                alt="routiarAdvantages1"
              />
            </div>
            <div className={styles.advantages__contents__box__text}>
              A solution that can be effectively utilized in the fields of
              architecture, plant MEP design, and construction.
            </div>
          </div>
          <div className={styles.advantages__contents__box}>
            <div className={styles.advantages__contents__box__image}>
              <img
                src="/images/routiar/routiarAdvantages2.png"
                alt="routiarAdvantages2"
              />
            </div>
            <div className={styles.advantages__contents__box__text}>
              Strategic planning, route generation, and immediate
              decision-making enabling time and cost savings.
            </div>
          </div>
          <div className={styles.advantages__contents__box}>
            <div className={styles.advantages__contents__box__image}>
              <img
                src="/images/routiar/routiarAdvantages3.png"
                alt="routiarAdvantages3"
              />
            </div>
            <div className={styles.advantages__contents__box__text}>
              Clear communication helps identify potential issues in the
              construction process in advance.
            </div>
          </div>
        </div>
      </div>
      <hr className={styles.hrDotted} />

      <div className={styles.awards}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>
          Awards and Certifications
        </h2>
        <div className={styles.awards__contents}>
          <div className={styles.awards__contents__box}>
            <div className={styles.awards__contents__box__image}>
              <img src="/images/awards/KES2023.png" alt="KES2023" />
            </div>
            <div className={styles.awards__contents__box__text}>
              <p>Organized by the Korea Electronic Association(KEA)</p>
              <h3 className={styles.h2}>KES Innovation Award, 2023</h3>
              <p className={styles.awards__contents__box__text_color}>
                ROUTi-AR, under its Korean trademark ROUTi-AR, received the
                Innovation Award for Converged New Technology. This award,
                organized by the Korea Electronic Association (KEA), the host of
                KES, recognizes innovative products and technological excellence
                that elevate the status of K-brands.
              </p>
            </div>
          </div>
          <div className={styles.awards__contents__box}>
            <div className={styles.awards__contents__box__image}>
              <img src="/images/awards/CES2024.png" alt="CES2024" />
            </div>
            <div className={styles.awards__contents__box__text}>
              <p>Organized by the Consumer Technology Association(CTA)</p>
              <h3 className={styles.h2}>CES Innovation Award,2024</h3>{" "}
              <p className={styles.awards__contents__box__text_color}>
                The ROUTi-AR product received the CES Innovation Award from the
                Consumer Technology Association (CTA), the organizer of CES,
                recognizing it as an outstanding product worldwide for its
                technology, design, and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className={styles.hrDotted} />
      <div className={styles.installGuides}>
        <h2 className={styles.h1}>Service Guide</h2>
        <div className={styles.installGuides__contents}>
          <h3 className={styles.h2}>System Review</h3>
          <p>
            Refer to the system requirements to ensure that your computer meets
            the minimum system requirements. You should download and install the
            version compatible with your operating system (32-bit or 64-bit).
            While it is possible to install and use a 32-bit program on a 64-bit
            operating system, it is not recommended. Verify your operating
            system and download the appropriate version. The system requirements
            will specify the minimum or recommended specifications for your
            computer. For optimal performance, it is recommended to use a system
            that meets or exceeds the recommended specifications.
          </p>

          <h3 className={styles.h2}>Preparing an SLZ User Account</h3>

          <p>
            The SLZ program operates through web authentication via the
            internet. Therefore, to use the SLZ program and technical services
            smoothly, it is essential to register as a member on the site. On
            the user site, you can register, withdraw, modify information, and
            check contract information. The ID and password registered here can
            be used to authenticate the SLZ program or access all technical
            documents and services on this site (technical service site). In
            cases where internet use is difficult, such as due to company
            security restricting access to external internet networks or travel
            to areas with uncertain internet environments, support can be
            provided through a separate inquiry.
          </p>

          <h3 className={styles.h2}>Installation Preparation</h3>

          <p>
            Before installing the program, it is advisable to check the
            following items in advance.
            <br />
            <br />
            <b>1. Obtain Administrator Rights</b>
            <br />
            <br />
            Ensure you have administrator rights on the computer where you plan
            to install the software. This step can usually be skipped for
            personal computers. However, if you are using a computer within a
            company with enhanced security, you will need to verify with your IT
            department to gain local user management rights that allow the
            installation of new software. You can check for administrator rights
            by navigating to Control Panel > User Accounts > Manage User
            Accounts.
            <br />
            <br />
            <b>
              2. Install Windows Updates, Disable Antivirus and Firewall, and
              Close Related Applications
            </b>
            <br />
            <br />
            Installing Windows updates is an excellent way to address security
            issues and system errors. While it is challenging to determine if a
            specific Windows update is directly related to installation
            failures, updating your system can help resolve general security
            issues and errors, normalizing the computer’s condition. During
            installation, essential components might require registry additions
            or modifications, and the use of system folders/files. Sometimes,
            antivirus software and firewalls can interfere with these processes.
            Temporarily disabling your antivirus and firewall during
            installation might resolve these issues. If the SLZ program
            integrates with other software such as MS Office, it is advisable to
            close those applications to prevent any interference during
            installation.
          </p>
        </div>
      </div> */}
      <hr className={styles.hrDotted} />
      <div className={styles.systemRequirement}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>
          System Requirements
        </h2>
        <div className={styles.systemRequirement__contents}>
          <table className={styles.systemRequirement__table}>
            <tbody>
              <tr>
                <th>Operating System</th>
                <td>Windows 64-bit systems</td>
              </tr>
              <tr>
                <th>Supported AR/XR Devices</th>
                <td>
                  MS Hololens 2, Android Tablets(Samsung Galaxy Tab S8 or
                  higher)
                </td>
              </tr>
              <tr>
                <th>Software</th>
                <td>ROUTi-AR, Autodesk Revit(versions 2024, 2025)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr className={styles.hrDotted} />
      <div className={styles.faq}>
        <h2 className={`${styles.h1} ${styles.heading1}`}>FAQ</h2>
        <div className={styles.faq__contents}>
          <hr className={styles.hr} />
          <div className={styles.faq__contents__text}>
            <div
              className={styles.faq__contents__text_titleSection}
              onClick={() => setFaqToggleOpen1((prevState) => !prevState)}
            >
              <div className={styles.faq__contents__text_title}>
                What are the differences between ROUTi-AR and AI?
              </div>

              {faqToggleOpen1 ? <IoIosArrowUp /> : <IoIosArrowForward />}
            </div>
            {faqToggleOpen1 && (
              <p>
                ROUTi-AR uses tablets and VR devices to visualize and optimize
                piping routes in real-time on-site. In contrast, ROUTi-AI is a
                PC-based software focused on automating and accelerating piping
                design for large-scale plants. Both improve accuracy and
                efficiency but serve different purposes and project scopes.
              </p>
            )}
          </div>
          <hr className={styles.hr} />
          <div className={styles.faq__contents__text}>
            <div
              className={styles.faq__contents__text_titleSection}
              onClick={() => setFaqToggleOpen2((prevState) => !prevState)}
            >
              <div className={styles.faq__contents__text_title}>
                How much time does it save in design?
              </div>
              {faqToggleOpen2 ? <IoIosArrowUp /> : <IoIosArrowForward />}
            </div>

            {faqToggleOpen2 && (
              <p>
                ROUTi-AR enables fast design reviews and adjustments on-site,
                significantly reducing design time. Without the need for
                drawing, routes are directly generated in AR, shortening review
                times. This can reduce overall project duration by around 30%.
              </p>
            )}
          </div>
          <hr className={styles.hr} />
          <div className={styles.buttonSection1}>
            <Button
              width={180}
              height={60}
              text="View all FAQ"
              onClick={() => navigate("/faq")}
            />
          </div>
        </div>
      </div>

      <div className={styles.bannerSection__contents}>
        <div>
          Meet ROUTi-AR now
          <br /> to enable on-site automatic MEP design
          <br />
          using AR technology.
        </div>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Link
            to="https://apps.autodesk.com/RVT/en/Detail/Index?id=5859382366253003207&appLang=en&os=Win64"
            target="_blank"
          >
            <Button
              className={styles.backgroundButton}
              width="260px"
              height="70px"
              border={isHovered ? "var(--color-accent)" : "white"}
              color={isHovered ? "var(--color-accent)" : "transparent"}
              fontColor="white"
              text="Download"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
export default RoutiAr;
