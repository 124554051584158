import { QueryClient, QueryClientProvider } from "react-query";
import Footer from "./components/footer/Footer";
import NavBar from "./components/navbar/NavBar";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const queryClient = new QueryClient();

function App() {
  ScrollToTop();
  return (
    <>
      <NavBar />
      <QueryClientProvider client={queryClient}>
        <Outlet />
      </QueryClientProvider>
      <Footer />
    </>
  );
}

export default App;
