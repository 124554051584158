import React, { useEffect, useMemo, useState } from "react";
import styles from "./BoardComponents.module.css";
import { useGlobalFilter, useTable, usePagination } from "react-table";
import Search from "./Search";
import { Link } from "react-router-dom";
import FaqCheckbox from "../../pages/faq/FaqCheckbox";
import NoticeCheckbox from "../../pages/notice/NoticeCheckbox";
import { fetchNews, fetchFaq } from "../../api/crud";

function BoardComponents({
  pages,
  line,
  showSearch,
  showCheckbox,
  initialSearch,
  showNumberColumn, // Number 열을 표시할지 여부를 제어하는 prop
}) {
  const [data, setData] = useState([]);
  const [isMobile, setIsMobile] = useState(false); // 모바일 환경인지 확인하는 상태
  const [error, setError] = useState(null);

  // 화면 크기 체크해서 모바일인지 아닌지 상태로 설정
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767); // 화면 너비가 767px 이하이면 모바일로 간주
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        let result;
        if (pages === "notice") {
          result = await fetchNews(); // notice 페이지일 경우 fetchNews 호출
        } else if (pages === "faq") {
          result = await fetchFaq(); // faq 페이지일 경우 fetchFaq 호출
        }
        setData(result);
      } catch (error) {
        setError(error);
      }
    };
    getData();
  }, [pages]); // pages가 바뀔 때만 데이터를 다시 가져옴

  const tableData = useMemo(() => {
    return (data || []).slice(0, line).map((item) => ({
      id: item.id,
      type: item.type,
      date: item.date,
      title: item.title || "Untitled", // 제목이 없을 경우 기본값 설정
      contents: item.contents || "No content available.", // 내용이 없을 경우 기본값 설정
      images: item.images || "/images/default_image.png", // 이미지가 없을 경우 기본 이미지 설정
    }));
  }, [data, line]);

  const columns = useMemo(
    () => {
      const cols = [];

      if (showNumberColumn && !isMobile) {
        cols.push({
          accessor: "id",
          Header: "",
        });
      }

      cols.push({
        accessor: "type",
        Header: !isMobile ? "Type" : "",
        Cell: ({ value }) => (isMobile ? "" : <span>{value}</span>),
      });

      cols.push(
        {
          accessor: "title",
          Header: "Title",
          Cell: ({ value, row }) => (
            <Link
              to={`/${pages}/${row.original.id}`}
              state={{
                id: row.original.id,
                type: row.original.type,
                date: row.original.date,
                title: row.original.title,
                contents: row.original.contents,
                images: row.original.images,
              }}
            >
              {value}
            </Link>
          ),
        },
        {
          accessor: "date",
          Header: "Date",
        }
      );

      return cols;
    },
    [pages, showNumberColumn, isMobile] // showNumberColumn이 변경될 때 columns를 재계산
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    { columns, data: tableData, initialState: { pageSize: 10, pageIndex: 0 } },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(initialSearch);
  }, [initialSearch, setGlobalFilter]);

  return (
    <div className={styles.tableSection}>
      <div className={styles.titleSection}>
        <div className={styles.titleSection__title}>
          {showCheckbox && pages === "faq" && (
            <FaqCheckbox onSubmit={setGlobalFilter} />
          )}
          {showCheckbox && pages === "notice" && (
            <NoticeCheckbox onSubmit={setGlobalFilter} />
          )}
        </div>
        <div className={styles.titleSection__search}>
          {showSearch && (
            <Search onSubmit={setGlobalFilter} initialSearch={initialSearch} />
          )}
        </div>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showSearch && (
        <div className={styles.bottomButtons}>
          <div></div>
          <div className={styles.pagination}>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <span>
              {pageIndex + 1} / {pageOptions.length}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
          </div>
          <select
            className={styles.selectBox}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} items per page
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default BoardComponents;
