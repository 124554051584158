import React, { useState } from "react";
import styles from "./NoticeWrite.module.css";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { addNews } from "../../api/crud";

function NoticeWrite() {
  const navigate = useNavigate();
  const [news, setNews] = useState({
    title: "",
    type: "",
    image: null,
    contents: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNews((prevNews) => ({
      ...prevNews,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNews((prevNews) => ({
      ...prevNews,
      [name]: files[0],
    }));
  };

  const handleSubmit = async () => {
    try {
      await addNews(news);
      navigate(-1); // navigate to the previous page after adding
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.writeSection}>
      <div className={styles.writeSectionContents}>
        <div className={styles.titleSection}>
          <h1 className={styles.title}>Write</h1>
          <span>| Notice</span>
        </div>
        <hr className={styles.hr} />
        <div className={styles.contentsSection}>
          <div className={styles.contentsSection__title}>
            <label htmlFor="title">
              Title
              <br />
              (Required)
            </label>
            <input
              type="text"
              id="title"
              name="title"
              placeholder="Please enter the title."
              required
              minLength="10"
              maxLength="100"
              value={news.title}
              onChange={handleChange}
            />
          </div>
          <div className={styles.contentsSection__type}>
            <div className={styles.contentsSection__type__title}>
              Type of Post
              <br />
              (Required)
            </div>
            <div className={styles.contentsSection__type__item}>
              <label htmlFor="Notice">
                <input
                  type="radio"
                  id="Notice"
                  name="type"
                  value="Notice"
                  checked={news.type === "Notice"}
                  onChange={handleChange}
                />
                Notice
              </label>
              <label htmlFor="ROUTi-AR">
                <input
                  type="radio"
                  id="ROUTi-AR"
                  name="type"
                  value="ROUTi-AR"
                  checked={news.type === "ROUTi-AR"}
                  onChange={handleChange}
                />
                ROUTi-AR
              </label>
              <label htmlFor="ROUTi-AI">
                <input
                  type="radio"
                  id="ROUTi-AI"
                  name="type"
                  value="ROUTi-AI"
                  checked={news.type === "ROUTi-AI"}
                  onChange={handleChange}
                />
                ROUTi-AI
              </label>
            </div>
          </div>
          <div className={styles.contentsSection__image}>
            <label htmlFor="image">
              Attach Image
              <br />
              (Optional)
            </label>
            <div className={styles.contentsSection__image__contents}>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className={styles.contentsSection__contents}>
            <label htmlFor="contents">
              Content
              <br />
              (Required)
            </label>
            <textarea
              id="contents"
              name="contents"
              placeholder="Please enter the content."
              required
              value={news.contents}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.postButton}>
          <Button text="Submit" primary onClick={handleSubmit} />
          <Button text="Cancel" onClick={() => navigate(-1)} />
        </div>
      </div>
    </div>
  );
}

export default NoticeWrite;
