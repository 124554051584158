import React, { useEffect, useState } from "react";
import styles from "./Solution.module.css";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

function Solution() {
  const { pathname } = useLocation();

  const [isfastButtonVisible, setIsFastButtonVisible] = useState(false);

  const handleScroll = () => {
    const isVisible = window.innerHeight < window.scrollY;
    setIsFastButtonVisible(isVisible);
  };
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className={styles.solutionSection}>
      <div
        className={styles.fastSection}
        style={{
          transform: isfastButtonVisible
            ? "translateY(0)"
            : "translateY(100px)",
          opacity: isfastButtonVisible ? 1 : 0,
          visibility: isfastButtonVisible ? "visible" : "hidden",
          transition:
            "transform 500ms ease, opacity 1000ms ease, visibility 1000ms ease",
        }}
      >
        <div className={styles.fastSection_button} onClick={moveToTop}>
          <MdKeyboardDoubleArrowUp />
        </div>
        {pathname === "/solution/routiar" ? (
          <NavLink to="/solution/routiai">
            <div className={styles.fastSection_button}>
              <img src="/images/routiai/routiai_icon.png" alt="routiai" />
            </div>
          </NavLink>
        ) : (
          <NavLink to="/solution/routiar">
            <div className={styles.fastSection_button}>
              <img src="/images/routiar/routiar_icon.png" alt="routiar" />
            </div>
          </NavLink>
        )}
      </div>

      <Outlet className={styles.outlet} />
      <div className={styles.bannerSection}></div>
    </section>
  );
}

export default Solution;
