import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerSection}>
        <section className={styles.left}>
          <ul className={styles.ul}>
            <p>
              <Link className={styles.link} to="/solution">
                Download
              </Link>
            </p>
            <li>
              <Link className={styles.link} to="/solution/routiai">
                ROUTi-AI
              </Link>
            </li>
            <li>
              <Link className={styles.link} to="/solution/routiar">
                ROUTi-AR
              </Link>
            </li>
          </ul>
          <ul className={styles.ul}>
            <Link className={styles.link} to="/notice">
              Latest Updates
            </Link>
          </ul>
          <ul className={styles.ul}>
            <Link className={styles.link} to="/faq">
              FAQ
            </Link>
          </ul>
          {/* <ul className={styles.ul}>
            <Link className={styles.link} to="/mypage">
              My Page
            </Link>
          </ul> */}
        </section>
        <section className={styles.right}>
          {/* <div>
            Private Policy |{" "}
            <Link className={styles.link} to="/termsofuse">
              Terms of Use
            </Link>
          </div> */}
          <div>
            <b>SLZ Inc.</b>
            <br />
            Email | <b>support@routi.ai</b>
            <br />
            LA Office |{" "}
            <b>19700 S. Vermont Ave., Suite #200, Torrance, CA, 90502 </b>
            <br />
            ⓒ2020. SLZ Inc. All rights reserved.
            <br />
          </div>
          <div className={styles.sns}>
            <Link
              className={styles.snsLink}
              to="https://www.linkedin.com/company/slz-inc"
              target="_blank"
            >
              <i class="fa-brands fa-linkedin"></i>
            </Link>
            <Link
              className={styles.snsLink}
              to="https://www.facebook.com/smartlz.slz/"
              target="_blank"
            >
              <i class="fa-brands fa-square-facebook"></i>
            </Link>
            <Link
              className={styles.snsLink}
              to="https://www.youtube.com/@slz-smartlandscapesystemz9"
              target="_blank"
            >
              <i class="fa-brands fa-youtube"></i>
            </Link>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
