import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styles from "./NavBar.module.css";
import { MdCancel } from "react-icons/md";

function NavBar() {
  const location = useLocation();
  const [isHovering, setIsHovering] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const checkDropdownActive = () => {
      const activePaths = ["/solution/routiar", "/solution/routiai"];
      const isPathActive = activePaths.includes(location.pathname);
      setIsDropdownActive(isPathActive);
    };

    checkDropdownActive();
  }, [location.pathname]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <div className={styles.navBox}>
      {isVisible && (
        <div className={styles.bannerSection}>
          <img
            className={styles.banner}
            src="/images/banner.svg"
            alt="banner"
            height={50}
          />
          <p>SLZ Selected as an NVIDIA Inception Partner!</p>
          <MdCancel style={{ fontSize: "30px" }} onClick={handleClick} />
        </div>
      )}
      <header className={styles.header}>
        <Link to="/">
          <div className={styles.navLogo}>
            <span>
              <img src="/images/logo.png" height={70} alt="logo" />
            </span>
            {/* <h1 className={styles.navTitle}>ROUTi Page</h1> */}
          </div>
        </Link>
        <nav className={styles.nav}>
          <NavLink
            to="/solution/routiai"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#1e7d4f" : "#191919",
              };
            }}
          >
            Download
          </NavLink>
          <NavLink
            to="/notice"
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#1e7d4f" : "#191919",
              };
            }}
          >
            Latest Updates
          </NavLink>
          <NavLink
            to="/faq"
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#1e7d4f" : "#191919",
              };
            }}
          >
            FAQ
          </NavLink>
          {/* <NavLink
            to="/login"
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#1e7d4f" : "#191919",
              };
            }}
          >
            <b>LOGIN</b>
          </NavLink>
          <NavLink
            to="/mypage"
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#1e7d4f" : "#191919",
              };
            }}
          >
            (My Page)
          </NavLink> */}
        </nav>
      </header>
      <div className={styles.bar}></div>
      {(isHovering || isDropdownActive) && (
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleMouseOver}
          className={styles.dropdownMenu}
        >
          <ul className={styles.menuBox}>
            <li>
              <NavLink
                to="/solution/routiai"
                className={({ isActive }) =>
                  isActive
                    ? `${styles.activeNavLink} ${styles.navLink}`
                    : styles.navLink
                }
              >
                <div className={styles.solutionName}>ROUTi-AI</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/solution/routiar"
                className={({ isActive }) =>
                  isActive
                    ? `${styles.activeNavLink} ${styles.navLink}`
                    : styles.navLink
                }
              >
                <div className={styles.solutionName}>ROUTi-AR</div>
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default NavBar;
