import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import styles from "./NotFound.module.css";

function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate(-1);
    }, 3000);
  }, []);
  return (
    <div className={styles.notfound}>
      Page not found. You will be redirected to the previous page in 3 seconds.
      <SyncLoader />
    </div>
  );
}

export default NotFound;
