import React, { useEffect, useState } from "react";
import styles from "./FaqEdit.module.css";
import Button from "../../components/ui/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { updateNews } from "../../api/crud";

function FaqEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const [news, setNews] = useState(location.state);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNews((prevNews) => ({ ...prevNews, [name]: value }));
  };

  const handleUpdateNews = async () => {
    try {
      await updateNews(news.id, news);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.editSection}>
      <div className={styles.editSectionContents}>
        <div className={styles.titleSection}>
          <h1 className={styles.title}>Edit Post</h1>
          <span>| Frequently Asked Questions</span>
        </div>
        <hr className={styles.hr} />
        <div className={styles.contentsSection}>
          <div className={styles.contentsSection__title}>
            <label htmlFor="title">
              Title
              <br />
              (Required)
            </label>
            <input
              type="text"
              id="title"
              name="title"
              placeholder="Please enter the title."
              required
              minLength="10"
              maxLength="100"
              value={news.title}
              onChange={handleChange}
            />
          </div>
          <div className={styles.contentsSection__type}>
            <div className={styles.contentsSection__type__title}>
              Type of Post
              <br />
              (Required)
            </div>
            <div className={styles.contentsSection__type__item}>
              <label htmlFor="Auth">
                <input
                  type="radio"
                  id="Auth"
                  name="type"
                  value="Auth"
                  checked={news.type === "Auth"}
                  onChange={handleChange}
                />
                Auth
              </label>
              <label htmlFor="Download">
                <input
                  type="radio"
                  id="Download"
                  name="type"
                  value="Download"
                  checked={news.type === "Download"}
                  onChange={handleChange}
                />
                Download
              </label>
              <label htmlFor="Solution">
                <input
                  type="radio"
                  id="Solution"
                  name="type"
                  value="Solution"
                  checked={news.type === "Solution"}
                  onChange={handleChange}
                />
                Solution
              </label>
              <label htmlFor="Payment">
                <input
                  type="radio"
                  id="Payment"
                  name="type"
                  value="Payment"
                  checked={news.type === "Payment"}
                  onChange={handleChange}
                />
                Payment
              </label>
              <label htmlFor="Etc">
                <input
                  type="radio"
                  id="Etc"
                  name="type"
                  value="Etc"
                  checked={news.type === "Etc"}
                  onChange={handleChange}
                />
                Etc
              </label>
            </div>
          </div>
          <div className={styles.contentsSection__image}>
            <label htmlFor="image">
              Attach Image
              <br />
              (Optional)
            </label>
            <div className={styles.contentsSection__image__contents}>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/png, image/jpeg"
                // 파일입력로직 넣기
              />
            </div>
          </div>
          <div className={styles.contentsSection__contents}>
            <label htmlFor="contents">
              Content
              <br />
              (Required)
            </label>
            <textarea
              id="contents"
              name="content"
              placeholder="Please enter the content."
              required
              value={news.contents}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.postButton}>
          <Button text="Submit" primary onClick={handleUpdateNews} />
          <Button text="Cancel" onClick={() => navigate(-1)} />
        </div>
      </div>
    </div>
  );
}

export default FaqEdit;
